import axios from "axios";
import GetToken from "../utils/getToken";
import { env } from "../env";

export function permissionsUsersByEmail(email) {
    return axios({
        method: "GET",
        url: `users/getPermissions`,
        params: { email },
        baseURL: env.REACT_APP_API_URL,
        validateStatus: null,
        headers: {
            Authorization: GetToken(),
        },
    });
}