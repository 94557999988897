import { Avatar, Button, Divider } from "@mui/material";
import { Col, Container, Nav, Row } from "react-bootstrap";
import React, { useState } from "react";
import ModalDecision from "../Modals/ModalDecision";
import { useNavigate, NavLink } from "react-router-dom";
import { Logout } from "../../services/Login/Login";
import { Mixpanel } from "../../utils/mixPanel";

const Sidebar = () => {
  const navigate = useNavigate();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const roleShortName = localStorage.getItem("role");

  const role = roleShortName === "EXTERNAL" ? "Solicitante" : "---";

  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const corporateUnitName = JSON.parse(localStorage.getItem("corporateUnit"));
  const corporateUnit = corporateUnitName?.name || "";

  const handleClose = () => {
    setOpenLogoutModal(false);
  };

  const handleLogoutAgree = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      await Logout({ refreshToken, email: payloadToken.email });
      setOpenLogoutModal(false);
      window.location.replace("/");
      Mixpanel.reset();
    } catch (error) {
      console.log("==============Error handleLogout======================");
      console.log(error);
      console.log("====================================");
      setOpenLogoutModal(false);
    }
  };

  const handleLogoutDisagree = () => {
    setOpenLogoutModal(false);
  };

  const externalMenu = [
    { content: "Inicio", icon: "homeIcon", route: "home", prefix: "external" },
    {
      content: "Crear",
      icon: "mailcon",
      route: "create",
      prefix: "external",
    },
  ];

  const handleMixPanelAnalytics = (item) => {
    Mixpanel.track(`Btn Sidebar Solicitante ${item.content}`, {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };

  return (
    <Container className="sidebar-base__container">
      {/**HEADER */}
      <Row className="sidebar-base__header-row">
        <Container fluid className="sidebar-base__header-row__header-container">
          <Row>
            <Avatar
              sx={{
                width: "5rem",
                height: "5rem",
                backgroundColor: "#00374F",
                fontSize: "2rem",
                fontFamily: "Roboto",
              }}
            >
              {payloadToken?.firstName?.toUpperCase().charAt(0) || "P"}{" "}
              {payloadToken?.firstSurname?.toUpperCase().charAt(0) || "J"}
            </Avatar>
          </Row>
          <Row className="sidebar-base__header-row__header-container__name">
            <p className="heading">
              {payloadToken.firstName} {payloadToken.firstSurname}
            </p>
          </Row>
          <Row className="sidebar-base__header-row__header-container__role">
            <p className="caption">{role}</p>
          </Row>
          <Row className="sidebar-base__header-row__header-container__role">
            <p className="caption">{corporateUnit}</p>
          </Row>
        </Container>
      </Row>

      <Divider variant="fullWidth" />

      {/**NAVBAR */}
      <Row className="sidebar-base__navbar-row">
        <Container fluid className="sidebar-base__navbar-row__navbar-container">
          <Nav className="flex-column">
            {externalMenu.map((item) => {
              return (
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "sidebar-base__navbar-row__navbar-container__nav-row__selected"
                      : "sidebar-base__navbar-row__navbar-container__nav-row"
                  }
                  key={item.content}
                  to={item.route}
                  style={{ textDecoration: "none" }}
                  onClick={() => handleMixPanelAnalytics(item)}
                >
                  <Row>
                    <Col
                      md={2}
                      className="sidebar-base__navbar-row__navbar-container__icon-container"
                    >
                      <img
                        className="sidebar-base__navbar-row__navbar-container__nav-icon"
                        src={require(`../../assets/svg/${item.icon}.svg`)}
                      />
                    </Col>
                    <Col className="sidebar-base__navbar-row__navbar-container__nav-element">
                      {item.content}
                    </Col>
                  </Row>
                </NavLink>
              );
            })}
          </Nav>
        </Container>
      </Row>

      {/**LOGOUT */}
      <Row className="sidebar-base__navbar-row__navbar-container__nav-row">
        <Button
          variant="text"
          className="sidebar-base__logout-row__button"
          onClick={() => {
            navigate("/external/help");
            Mixpanel.track("Btn Sidebar Solicitante Soporte técnico", {
              email: payloadToken?.email,
              companyName: payloadToken?.companyName,
            });
          }}
        >
          <Row style={{ paddingLeft: "5%" }}>
            <Col md={2} className="sidebar-base__logout-row__logout-container">
              <img
                className="sidebar-base__logout-row__logout-icon"
                src={require("../../assets/svg/helpIcon.svg").default}
              />
            </Col>
            <Col className="sidebar-base__logout-row__logout-text">
              Soporte técnico
            </Col>
          </Row>
        </Button>
      </Row>
      <Row className="sidebar-base__navbar-row__navbar-container__nav-row">
        <Button
          variant="text"
          className="sidebar-base__logout-row__button"
          onClick={() => {
            setOpenLogoutModal(true);
            Mixpanel.track("Btn Sidebar Solicitante Cerrar Sesión", {
              email: payloadToken?.email,
              companyName: payloadToken?.companyName,
            });
          }}
        >
          <Row style={{ paddingLeft: "5%" }}>
            <Col md={3} className="sidebar-base__logout-row__logout-container">
              <img
                className="sidebar-base__logout-row__logout-icon"
                src={require("../../assets/svg/logoutIcon.svg").default}
              />
            </Col>
            <Col className="sidebar-base__logout-row__logout-text">
              Cerrar sesión
            </Col>
          </Row>
        </Button>
      </Row>

      <Divider />

      {/**FOOTER */}

      <Row className="sidebar-base__logo-row">
        <Col md={3} className="sidebar-base__logo-row__logo-container">
          <img
            className="sidebar-base__logo-row__logo-svg"
            src={require("../../assets/svg/singleLogo.svg").default}
          />
        </Col>
        <Col className="sidebar-base__logo-row__logo-title">
          <p>Legal AI</p>
        </Col>
      </Row>

      <ModalDecision
        title={"Cerrar sesión"}
        message={"¿Está seguro?"}
        open={openLogoutModal}
        onClose={handleClose}
        agreeText="Cerrar sesión"
        disagreeText="Cancelar"
        handleAgree={handleLogoutAgree}
        handleDisagree={handleLogoutDisagree}
      />
    </Container>
  );
};

export default Sidebar;
