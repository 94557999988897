import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalInputText = ({
  title,
  message,
  agreeText,
  placeholder,
  disagreeText,
  handleAgree,
  handleDisagree,
  onClose,
  open,
  disableDisagreeText = false,
  inputType="textarea",
  validation = {},
}) => {
  const [returnMessage, setReturnMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setValue("text", "");
  }, [open]);

  const handleSubmitRequest = () => {
    handleAgree(returnMessage);
  };


  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <Form onSubmit={handleSubmit(handleSubmitRequest)}>
          <DialogTitleContainer onClose={handleClose}>
            <label className="heading__primary-color">{title}</label>
          </DialogTitleContainer>
          <DialogContent className="caption" dividers>
            <p className="caption" style={{ padding: "2% 0" }}>
              {message}
            </p>
            <Form.Group>
              <Form.Control
                {...register("text", validation)}
                as={inputType}
                placeholder={placeholder}
                bsPrefix={"input-group-container__no-icon label"}
                onChange={(e) => {
                  setReturnMessage(e.target.value);
                }}
              />
              <div className="caption custom-input__error">
                {errors.text?.message}
              </div>
            </Form.Group>
          </DialogContent>
          <DialogActions>
            <Row>
              {!disableDisagreeText && (
                <Col>
                  <Button
                    variant="text"
                    className="custom-input__button__secondary-color__forced"
                    onClick={handleDisagree}
                    disabled={disableDisagreeText}
                  >
                    {disagreeText}
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color__forced"
                  onClick={() => {
                    handleAgree(returnMessage);
                  }}
                >
                  {agreeText}
                </Button>
              </Col>
            </Row>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default ModalInputText;
