import { Button, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import {
  DEFAULT_PART,
  DEFAULT_SIGNATORY,
  signatoriesTypes,
} from "../../utils/typesSignatures";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrum from "../../pages/parts/breadcrum";
import SectionTitle from "../../components/sectionTitle";
import ReactHtmlParser from "react-html-parser";
import { NUMBERINGWORDSO } from "../../utils/numberings";
import { getContractTypeById } from "../../services/ContractType/ContractTypeServices";
import LoadingContent from "../../components/LoadingContent";
import { CODES } from "../../utils/codes";
import FillBlankspacesBox from "../../components/Blankspaces/FillBlankspacesBox";
import ProgressBar from "../../components/Blankspaces/ProgressBar";
import {
  getAllBlankSpaces,
  getFillBlankSpaces,
} from "../../utils/countBlankspaces";
import { WordCreator } from "../../utils/WordCreator";

import { PaletteOutlined } from "@mui/icons-material";
import SelectHeadings from "./selectHeadings";
import { getSelectedHeader } from "../../reducers/headingsSlice";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../utils/mixPanel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { SelectPartForContract } from "./selectPartForContract";
import { SelectSignatoryForContract } from "./selectSignatoryForContract";
import BlockerPage from "../../components/blocker/blocker";
import { handleCopyClick } from "../../utils/copyText";
import botImage from "../../assets/svg/bot.png";
import dateFormat from "dateformat";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import PopoverActions from "../../components/popover/PopoverActions";
import { removeHtmlTags } from "../../utils/removeHTMLTags";

export const ViewContract = () => {
  //hooks
  const navigate = useNavigate();
  const { id } = useParams();

  //state
  const [contractTypeInfo, setContractTypeInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [openSelectPart, setOpenSelectPart] = useState(false);

  const [selectPart, setSelectPart] = useState({
    indexRole: null,
    indexPart: null,
    part: null,
  });
  const [openSelectSignatory, setOpenSelectSignatory] = useState(false);
  const [otherSignatoryInfo, setOtherSignatoryInfo] = useState({
    indexSignatory: null,
  });

  const [signatoryInfo, setSignatoryInfo] = useState({
    indexRole: null,
    indexPart: null,
    signatoryIndex: null,
    part: null,
  });

  const [signatoryType, setSignatoryType] = useState("");
  const [selectHeadings, setSelectHeadings] = useState(false);
  const [countGeneralBlankspaces, setCountGeneralBlankspaces] = useState(0);
  const selectedHeader = useSelector(getSelectedHeader);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  //handles
  const handleRequestContractTypeInfo = async () => {
    try {
      setIsLoading(true);
      const { _id } = JSON.parse(localStorage.getItem("corporateUnit"));
      const contractTypeInfoRequest = await getContractTypeById({
        corporateUnit: _id,
        id,
      });
      if (
        contractTypeInfoRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        contractTypeInfoRequest.data.success
      ) {
        const partA = {
          ...contractTypeInfoRequest.data.responseMessage.parts.partA,
          gender: "M",
          personType: "natural",
          blankspaces: signatoriesTypes.SignatariOPN.blankspaces,
          text: signatoriesTypes.SignatariOPN.text,
          parts: [DEFAULT_PART],
        };
        const partB = {
          ...contractTypeInfoRequest.data.responseMessage.parts.partB,
          gender: "M",
          personType: "natural",
          blankspaces: signatoriesTypes.SignatariOPN.blankspaces,
          text: signatoriesTypes.SignatariOPN.text,
          parts: [DEFAULT_PART],
        };

        let formatedParts = [partA, partB];

        if (contractTypeInfoRequest.data.responseMessage.parts.partC) {
          const partC = {
            ...contractTypeInfoRequest.data.responseMessage.parts.partC,
            gender: "M",
            personType: "natural",
            blankspaces: signatoriesTypes.SignatariOPN.blankspaces,
            text: signatoriesTypes.SignatariOPN.text,
            parts: [DEFAULT_PART],
          };
          formatedParts.push(partC);
        }

        if (contractTypeInfoRequest.data.responseMessage.parts.partD) {
          const partD = {
            ...contractTypeInfoRequest.data.responseMessage.parts.partD,
            gender: "M",
            personType: "natural",
            blankspaces: signatoriesTypes.SignatariOPN.blankspaces,
            text: signatoriesTypes.SignatariOPN.text,
            parts: [DEFAULT_PART],
          };
          formatedParts.push(partD);
        }
        setContractTypeInfo({
          ...contractTypeInfoRequest.data.responseMessage,
          parts: formatedParts,
          currentTitle: contractTypeInfoRequest?.data?.responseMessage?.name,
          otherSignatories: [],
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePartData = ({ indexRole, indexPart, newInfo }) => {
    setContractTypeInfo((prevInfo) => {
      let tempInfoContract = { ...prevInfo };
      if (newInfo.gender === "M" && newInfo.personType === "natural") {
        newInfo.text = signatoriesTypes.SignatariOPN.text;
        newInfo.blankspaces = signatoriesTypes.SignatariOPN.blankspaces;
      }
      if (newInfo.gender === "M" && newInfo.personType === "legal") {
        newInfo.text = signatoriesTypes.SignatariOPJ.text;
        newInfo.blankspaces = signatoriesTypes.SignatariOPJ.blankspaces;
      }
      if (newInfo.gender === "F" && newInfo.personType === "natural") {
        newInfo.text = signatoriesTypes.SignatariAPN.text;
        newInfo.blankspaces = signatoriesTypes.SignatariAPN.blankspaces;
      }
      if (newInfo.gender === "F" && newInfo.personType === "legal") {
        newInfo.text = signatoriesTypes.SignatariAPJ.text;
        newInfo.blankspaces = signatoriesTypes.SignatariAPJ.blankspaces;
      }
      tempInfoContract.parts[indexRole].parts[indexPart] = newInfo;
      return tempInfoContract;
    });
  };

  const handleGetFillBlankspacesParts = () => {
    for (let role of contractTypeInfo?.parts || []) {
      for (let part of role.parts || []) {
        for (let blankSpace of part.blankspaces || []) {
          if (blankSpace.value === "") {
            return false;
          }
        }
      }
    }
    return true;
  };

  const handleGetFillBlankspacesSignatories = () => {
    let signatoriesBlank = !contractTypeInfo?.parts?.some((role) =>
      role.parts.some((part) =>
        part.signatories.some((signatory) =>
          signatory.blankspaces.some((blank) => blank.value === "")
        )
      )
    );

    let otherSignatoriesBlank = !contractTypeInfo?.otherSignatories?.some(
      (signatory) => signatory.blankspaces.some((blank) => blank.value === "")
    );

    return signatoriesBlank && otherSignatoriesBlank;
  };
  const handleChangeDocumentTitleData = ({ newTitle, noTitle }) => {
    setContractTypeInfo((prevInfo) => ({
      ...prevInfo,
      name: newTitle,
      documentTitle: !noTitle ? newTitle : null,
    }));
  };

  const handleFillBlankspacesParts = ({
    newText,
    indexPart,
    indexBlank,
    nameBlank,
    indexRole,
    typePart,
  }) => {
    setContractTypeInfo((prevInfo) => {
      let tempInfoContract = { ...prevInfo };
      const formatedTextPart = handleFillBlankspaces(
        tempInfoContract.parts[indexRole].parts[indexPart].text,
        nameBlank,
        newText
      );
      let tempInfoPartChanged = {
        ...tempInfoContract.parts[indexRole].parts[indexPart],
        text: formatedTextPart,
        blankspaces: tempInfoContract.parts[indexRole].parts[
          indexPart
        ].blankspaces.map((blank, index) => {
          if (index === indexBlank) {
            return { ...blank, value: newText };
          } else return blank;
        }),
      };
      tempInfoContract.parts[indexRole].parts[indexPart] = tempInfoPartChanged;
      return tempInfoContract;
    });

    if (typePart) {
      if (indexBlank === 0) {
        handleFillBlankspacesSignatories({
          newText,
          indexPart,
          indexBlank: 1,
          nameBlank: "blankSpace-2",
          indexRole,
          indexSignatory: 0,
        });
      } else if (indexBlank === 1) {
        handleFillBlankspacesSignatories({
          newText,
          indexPart,
          indexBlank: 2,
          nameBlank: "blankSpace-3",
          indexRole,
          indexSignatory: 0,
        });
      } else if (indexBlank === 2) {
        handleFillBlankspacesSignatories({
          newText,
          indexPart,
          indexBlank: 3,
          nameBlank: "blankSpace-4",
          indexRole,
          indexSignatory: 0,
        });
      }
    } else {
      if (indexBlank === 4) {
        handleFillBlankspacesSignatories({
          newText,
          indexPart,
          indexBlank: 1,
          nameBlank: "blankSpace-2",
          indexRole,
          indexSignatory: 0,
        });
      } else if (indexBlank === 5) {
        handleFillBlankspacesSignatories({
          newText,
          indexPart,
          indexBlank: 2,
          nameBlank: "blankSpace-3",
          indexRole,
          indexSignatory: 0,
        });
      } else if (indexBlank === 6) {
        handleFillBlankspacesSignatories({
          newText,
          indexPart,
          indexBlank: 3,
          nameBlank: "blankSpace-4",
          indexRole,
          indexSignatory: 0,
        });
      }
    }
  };

  const handleFillBlankspacesSignatories = ({
    newText,
    indexPart,
    indexBlank,
    nameBlank,
    indexRole,
    indexSignatory,
    signatoryId,
  }) => {
    setContractTypeInfo((prevInfo) => {
      const tempInfoContract = { ...prevInfo };
      const tempSignatories = [
        ...tempInfoContract.parts[indexRole].parts[indexPart].signatories,
      ];

      const formatedTextPart = handleFillBlankspaces(
        tempSignatories[indexSignatory].text,
        nameBlank,
        newText
      );

      const tempBlankspaces = tempSignatories[indexSignatory].blankspaces.map(
        (blank, index) => {
          return index === indexBlank ? { ...blank, value: newText } : blank;
        }
      );

      tempSignatories[indexSignatory] = {
        ...tempSignatories[indexSignatory],
        text: formatedTextPart,
        blankspaces: tempBlankspaces,
        ...(signatoryId && { _id: signatoryId }),
      };

      tempInfoContract.parts[indexRole].parts[indexPart] = {
        ...tempInfoContract.parts[indexRole].parts[indexPart],
        signatories: tempSignatories,
      };

      return tempInfoContract;
    });
  };
  const handleFillBlankspacesOtherSignatories = ({
    newText,
    indexSignatory,
    indexBlank,
    nameBlank,
    signatoryId,
  }) => {
    setContractTypeInfo((prevInfo) => {
      let tempInfoContract = { ...prevInfo };
      const formatedTextPart = handleFillBlankspaces(
        tempInfoContract.otherSignatories[indexSignatory].text,
        nameBlank,
        newText
      );
      let tempInfoPartChanged = {
        ...tempInfoContract.otherSignatories[indexSignatory],
        text: formatedTextPart,
        ...(signatoryId && { _id: signatoryId }),
        blankspaces: tempInfoContract.otherSignatories[
          indexSignatory
        ].blankspaces.map((blank, index) => {
          if (index === indexBlank) {
            return { ...blank, value: newText };
          } else return blank;
        }),
      };
      tempInfoContract.otherSignatories[indexSignatory] = tempInfoPartChanged;
      return tempInfoContract;
    });
  };

  const handleFillBlankspaces = (textHtml, name, value) => {
    let newValue = value;

    if (!textHtml) return "";

    if (!name) return textHtml;

    if (!value) {
      newValue = name.replace("blankSpace-", "ESPACIO #");
    }

    //Convertimos el string html a un Object Document
    const parser = new DOMParser();
    let htmlElement = parser.parseFromString(textHtml, "text/html");

    //Validamos que exista la data para cambiar su valor
    if (htmlElement?.querySelector(`[data-blank-space="${name}"]`)) {
      htmlElement.querySelector(
        `[data-blank-space="${name}"]`
      ).innerText = `¬${newValue}¬`;
    }

    //Extraemos de Object Document el HTML String
    const stringifiedDocument = htmlElement.documentElement.outerHTML;

    //Limpiamos el HTML string para que no tenga etiquetas principales del html
    const cleanedString = stringifiedDocument.replace(
      /<\/?(html|head|body)>/gi,
      ""
    );

    return cleanedString;
  };

  const handleBlankspacesClause = ({ text, name, value, id }) => {
    const newText = handleFillBlankspaces(text, name, value);
    setContractTypeInfo((prevInfo) => {
      let tempInfoContract = { ...prevInfo };
      const newClausesList = tempInfoContract.clauses.map((clause) => {
        if (clause.clause._id === id) {
          return {
            ...clause,
            clause: {
              ...clause.clause,
              text: newText,
              blankspaces: clause.clause.blankspaces.map(
                (blankspace, index) => {
                  if (blankspace.name === name) {
                    return { ...blankspace, value: value };
                  }
                  return blankspace;
                }
              ),
            },
          };
        }
        return clause;
      });
      return { ...prevInfo, clauses: newClausesList };
    });
  };
  const handleBlankspacesSubclause = ({
    text,
    name,
    value,
    index,
    clauseId,
  }) => {
    const newText = handleFillBlankspaces(text, name, value);
    setContractTypeInfo((prevInfo) => {
      let tempInfoContract = { ...prevInfo };

      const formatedClausesList = tempInfoContract.clauses.map((clause) => {
        if (clause.clause._id === clauseId) {
          const clauseFormat = {
            ...clause,
            clause: {
              ...clause.clause,
              subclauses: clause.clause.subclauses.map(
                (subclause, indexSubclause) => {
                  if (index === indexSubclause) {
                    return {
                      ...subclause,
                      subclause: {
                        ...subclause.subclause,
                        text: newText,
                        blankspaces: subclause.subclause.blankspaces.map(
                          (blankspace) => {
                            if (blankspace.name === name) {
                              return { ...blankspace, value: value };
                            }
                            return blankspace;
                          }
                        ),
                      },
                    };
                  }
                  return subclause;
                }
              ),
            },
          };
          return clauseFormat;
        }
        return clause;
      });
      return { ...tempInfoContract, clauses: formatedClausesList };
    });
  };
  const fillBlankSpacesSubClauseChild = ({
    childs,
    indexChild,
    value,
    newText,
    name,
  }) => {
    if (!childs.length) return [];
    return childs.map((child, index) => {
      if (index === indexChild) {
        return {
          ...child,
          text: newText,
          blankspaces: child.blankspaces.map((blankspace) => {
            if (blankspace.name === name) {
              return { ...blankspace, value };
            }
            return blankspace;
          }),
        };
      }
      return child;
    });
  };
  const handleBlankspacesSubclauseChild = (
    text,
    name,
    value,
    indexSub,
    indexChild,
    clauseId
  ) => {
    const newText = handleFillBlankspaces(text, name, value);
    setContractTypeInfo((prev) => {
      let tempInfoContract = { ...prev };
      const formatedClausesList = tempInfoContract.clauses.map((clause) => {
        if (clause.clause._id === clauseId) {
          const updateClause = {
            ...clause,
            clause: {
              ...clause.clause,
              subclauses: clause.clause.subclauses.map((sub, index) => {
                if (index === indexSub) {
                  return {
                    ...sub,
                    subclause: {
                      ...sub.subclause,
                      childs: fillBlankSpacesSubClauseChild({
                        childs: sub.subclause.childs,
                        indexChild,
                        value,
                        newText,
                        name,
                      }),
                    },
                  };
                }
                return sub;
              }),
            },
          };
          return updateClause;
        }
        return clause;
      });
      return { ...tempInfoContract, clauses: formatedClausesList };
    });
  };
  const handleBlankspacesParagraphs = (text, name, value, index, clauseId) => {
    const newText = handleFillBlankspaces(text, name, value);
    setContractTypeInfo((prev) => {
      let tempInfoContract = { ...prev };

      const formatedClausesList = tempInfoContract.clauses.map((clause) => {
        if (clause.clause._id === clauseId) {
          const clauseFormat = {
            ...clause,
            clause: {
              ...clause.clause,
              paragraphs: clause.clause.paragraphs.map(
                (paragraph, indexSubclause) => {
                  if (index === indexSubclause) {
                    return {
                      ...paragraph,
                      paragraph: {
                        ...paragraph.paragraph,
                        text: newText,
                        blankspaces: paragraph.paragraph.blankspaces.map(
                          (blankspace, index) => {
                            if (blankspace.name === name) {
                              return { ...blankspace, value: value };
                            }
                            return blankspace;
                          }
                        ),
                      },
                    };
                  }
                  return paragraph;
                }
              ),
            },
          };
          return clauseFormat;
        }
        return clause;
      });
      return { ...tempInfoContract, clauses: formatedClausesList };
    });
  };

  const handleCountGeneralFillBlankspaces = () => {
    if (contractTypeInfo) {
      let count = 0;
      count += contractTypeInfo.documentTitle ? 1 : 0;
      count += handleGetFillBlankspacesParts() ? 1 : 0;
      count += handleGetFillBlankspacesSignatories() ? 1 : 0;
      const formatedListClause = contractTypeInfo?.clauses?.filter(
        (clause) => getAllBlankSpaces({ clause: clause.clause }) !== 0
      );
      formatedListClause.forEach((clause) => {
        const fillBlankSpaces = getFillBlankSpaces({ clause: clause.clause });
        const allBlankSpaces = getAllBlankSpaces({ clause: clause.clause });
        const isEqualToOne = fillBlankSpaces / allBlankSpaces === 1;
        const isCeroBlankSpaces = allBlankSpaces === 0;
        if (isEqualToOne || isCeroBlankSpaces) {
          count += 1;
        }
      });
      const percentageComplete = Math.trunc(
        (count / (formatedListClause.length + 3)) * 100
      );
      setCountGeneralBlankspaces(percentageComplete);
    }
  };

  const handleApplyPersonalization = () => {
    setSelectHeadings(true);
    Mixpanel.track("Solicitante Btn Membretes", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };

  const handleAddExternalSignatory = (index) => {
    setContractTypeInfo((prevItems) => {
      const updatedSelectedItems = [...prevItems.otherSignatories];

      if (updatedSelectedItems[index]) {
        updatedSelectedItems.splice(index, 1);
      } else {
        updatedSelectedItems.push({
          blankspaces: signatoriesTypes.OtherSignatory.blankspaces,
          text: signatoriesTypes.OtherSignatory.text,
        });
      }

      return {
        ...prevItems,
        otherSignatories: updatedSelectedItems,
      };
    });
  };

  const handleAddPart = (indexRole, indexPart) => {
    setContractTypeInfo((prevItems) => {
      const updatedSelectedItems = [...prevItems.parts];

      if (updatedSelectedItems[indexRole].parts[indexPart]) {
        updatedSelectedItems[indexRole].parts.splice(indexPart, 1);
      } else {
        updatedSelectedItems[indexRole].parts.push(DEFAULT_PART);
      }

      return {
        ...prevItems,
        parts: updatedSelectedItems,
      };
    });
  };

  const handleAddSignatory = (indexPart, indexRole, indexSignatory) => {
    setContractTypeInfo((prevItems) => {
      const updatedSelectedItems = [...prevItems.parts];

      const currentSignatories =
        updatedSelectedItems[indexRole]?.parts[indexPart]?.signatories || [];

      const updatedSignatories = [...currentSignatories];

      if (currentSignatories[indexSignatory]) {
        updatedSignatories.splice(indexSignatory, 1);
      } else {
        updatedSignatories.push(DEFAULT_SIGNATORY);
      }

      updatedSelectedItems[indexRole].parts[indexPart] = {
        ...updatedSelectedItems[indexRole].parts[indexPart],
        signatories: updatedSignatories,
      };

      return {
        ...prevItems,
        parts: updatedSelectedItems,
      };
    });
  };
  //useEffect
  useEffect(() => {
    handleRequestContractTypeInfo();
  }, []);
  useEffect(() => {
    handleCountGeneralFillBlankspaces();
  }, [contractTypeInfo]);

  console.log(contractTypeInfo);

  const flashItemSelected = (idItem) => {
    let item = document.getElementById(idItem);
    const docView = document.getElementById("content-document");
    const keyframes = [
      { backgroundColor: "#FFC4C0" },
      { backgroundColor: "#FFFFFF" },
    ];
    const options = { duration: 4000, iterations: 1 };
    const elementRect = item.getBoundingClientRect();
    const position =
      docView.scrollTop +
      (elementRect.top - docView.getBoundingClientRect().height / 2);
    docView.scrollTo({
      top: position,
      behavior: "smooth",
    });
    if (item) item.animate(keyframes, options);
  };

  console.log(contractTypeInfo);

  if (openSelectSignatory) {
    return (
      <SelectSignatoryForContract
        openSelectSignatory={openSelectSignatory}
        setOpenSelectSignatory={setOpenSelectSignatory}
        currentDocument={contractTypeInfo}
        type={signatoryType}
        handleFillBlankspacesOtherSignatories={
          handleFillBlankspacesOtherSignatories
        }
        otherSignatoryInfo={otherSignatoryInfo}
        signatoryInfo={signatoryInfo}
        handleFillBlankspacesSignatories={handleFillBlankspacesSignatories}
      />
    );
  } else if (openSelectPart) {
    return (
      <SelectPartForContract
        openSelectPart={openSelectPart}
        setOpenSelectPart={setOpenSelectPart}
        currentDocument={contractTypeInfo}
        selectPart={selectPart}
        handleFillBlankspacesParts={handleFillBlankspacesParts}
        handleChangePartData={handleChangePartData}
        handleFillBlankspacesSignatories={handleFillBlankspacesSignatories}
      />
    );
  } else if (selectHeadings) {
    return (
      <SelectHeadings
        selectHeadings={selectHeadings}
        setSelectHeadings={setSelectHeadings}
        currentDocument={contractTypeInfo}
      />
    );
  } else {
    return (
      <Container fluid className="custom-accordion__container-blankspaces ">
        {isLoading && <LoadingContent />}
        <Row>
          <Breadcrum />
          <BlockerPage
            blockNavigate={true}
            message={
              "Si continúas esta acción es posible que pierdas algunos datos."
            }
          />
        </Row>
        <Row xs={"auto"}>
          <SectionTitle title="Nuevo contrato" />
        </Row>
        <br />
        <Row className="mb-4">
          <p className="caption">
            Diligencia la información a la izquierda y visualiza tu contrato en
            la sección derecha.
          </p>
        </Row>
        <Row>
          <Col
            xs={4}
            className="container-blankspaces custom-accordion__container-headers"
          >
            <FillBlankspacesBox
              title={"Nombre del documento"}
              customBox={
                <Container fluid>
                  <Row style={{ margin: "1% 0" }} className="caption">
                    Escribe el nombre del documento:
                  </Row>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Control
                        as={"textarea"}
                        placeholder={contractTypeInfo?.name}
                        bsPrefix={"input-group-container__no-icon label"}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (!value) {
                            value = contractTypeInfo?.currentTitle;
                            handleChangeDocumentTitleData({
                              newTitle: value,
                              noTitle: true,
                            });
                          } else {
                            handleChangeDocumentTitleData({
                              newTitle: value,
                              noTitle: false,
                            });
                          }
                        }}
                        onFocus={() => {
                          flashItemSelected("name-document");
                        }}
                      />
                    </Form.Group>
                  </Row>
                </Container>
              }
              isCompleteCustomBox={!!contractTypeInfo?.documentTitle}
            />
            <FillBlankspacesBox
              title={"Partes"}
              isCompleteCustomBox={handleGetFillBlankspacesParts()}
              customBox={contractTypeInfo?.parts?.map((role, indexRole) => (
                <Container
                  key={role._id}
                  fluid
                  style={{ margin: "3% 0" }}
                  onClick={() => {
                    flashItemSelected(`part-${indexRole}`);
                  }}
                >
                  <Row className="mb-4 mt-4">
                    <Col xs={4} className={"heading"}>
                      {role.name}
                    </Col>
                  </Row>
                  {role?.parts?.map((part, indexPart) => {
                    return (
                      <>
                        <Row className="mb-4 mt-2">
                          <Col xs={12}>
                            <Button
                              variant="contained"
                              className="custom-input__button__primary-color"
                              startIcon={<AccountCircleIcon fontSize="large" />}
                              onClick={() => {
                                setOpenSelectPart(true);
                                setSelectPart({
                                  indexRole,
                                  indexPart,
                                  part,
                                });
                              }}
                            >
                              Cargar parte desde biblioteca
                            </Button>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col xs={6} className={"label"}>
                            Es Persona jurídica
                          </Col>
                          <Col xs={4}>
                            {part.partInfo?.relatedSignatories[0]
                              ?.limitations && (
                              <PopoverActions
                                parentId="clause-parent"
                                type="xlarge"
                                classNameButton=""
                                description={
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col xs={12} md={4}>
                                        <img
                                          alt="Preview Bot image"
                                          className="bot__image"
                                          src={botImage}
                                        />
                                      </Col>

                                      <Col xs={12} md={8}>
                                        <Row>
                                          <Col xs={12}>
                                            <span className="home-init-cards-row__title-row">
                                              Limitaciones a la representación
                                              legal:{" "}
                                            </span>
                                          </Col>

                                          {part.partInfo?.relatedSignatories[0]
                                            ?.signatory?.createdAt && (
                                            <Col
                                              xs={12}
                                              className="text-start mb-3"
                                            >
                                              <span className="caption">
                                                {`Creado el
                                              ${dateFormat(
                                                part.partInfo
                                                  ?.relatedSignatories[0]
                                                  ?.signatory?.createdAt,
                                                "d 'de' mmmm 'del 'yyyy"
                                              )}
                                              `}
                                              </span>
                                            </Col>
                                          )}

                                          {part.partInfo?.relatedSignatories[0]
                                            ?.limitationsUpdatedDate && (
                                            <Col
                                              xs={12}
                                              className="text-start mb-3"
                                            >
                                              <span className="caption">
                                                {`Modificado el ${dateFormat(
                                                  part.partInfo
                                                    ?.relatedSignatories[0]
                                                    ?.limitationsUpdatedDate,
                                                  "d 'de' mmmm 'del 'yyyy"
                                                )}`}{" "}
                                              </span>
                                            </Col>
                                          )}

                                          <Col
                                            xs={12}
                                            className="container__max-height"
                                          >
                                            <span className="caption">
                                              {ReactHtmlParser(
                                                part.partInfo
                                                  ?.relatedSignatories[0]
                                                  ?.limitations
                                              )}
                                            </span>
                                          </Col>

                                          <Col
                                            xs={12}
                                            className="mt-5 text-start"
                                          >
                                            <span
                                              className="heading__hover cursor__pointer"
                                              onClick={() =>
                                                handleCopyClick(
                                                  removeHtmlTags(
                                                    part.partInfo
                                                      ?.relatedSignatories[0]
                                                      ?.limitations
                                                  )
                                                )
                                              }
                                            >
                                              Copiar texto{" "}
                                            </span>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </div>
                                }
                                icon={
                                  <ReportOutlinedIcon
                                    fontSize="large"
                                    style={{ fill: "#F9600B" }}
                                  />
                                }
                              />
                            )}
                          </Col>
                          <Col xs={1}>
                            <Form.Check
                              type="switch"
                              style={{ fontSize: "150%" }}
                              checked={part.personType === "legal"}
                              onChange={(e) => {
                                handleChangePartData({
                                  indexRole,
                                  indexPart,
                                  newInfo: {
                                    ...part,
                                    personType: e.target.checked
                                      ? "legal"
                                      : "natural",
                                  },
                                });
                              }}
                            />
                          </Col>
                        </Row>
                        {part.blankspaces.map((blank, indexBlank) => (
                          <div key={blank.index}>
                            <Row style={{ margin: "1% 0" }} className="caption">
                              {blank.label}
                            </Row>
                            <Row>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  as={"textarea"}
                                  placeholder={`Ingresar el ${blank.label.toLowerCase()}`}
                                  bsPrefix={
                                    "input-group-container__no-icon label"
                                  }
                                  onChange={(e) => {
                                    handleFillBlankspacesParts({
                                      newText: e.target.value,
                                      indexRole,
                                      indexBlank,
                                      indexPart,
                                      nameBlank: blank.name,
                                      typePart: part.personType === "natural",
                                    });
                                  }}
                                  value={blank?.value || ""}
                                />
                              </Form.Group>
                            </Row>
                          </div>
                        ))}

                        <Row className="mb-3">
                          <Col xs={6} className={"label"}>
                            Agregar parte
                          </Col>
                          <Col xs={4}></Col>
                          <Col xs={1}>
                            <Form.Check
                              type="switch"
                              checked={
                                contractTypeInfo?.parts[indexRole].parts[
                                  indexPart + 1
                                ]
                              }
                              style={{ fontSize: "150%" }}
                              onChange={(e) => {
                                handleAddPart(indexRole, indexPart + 1);
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </Container>
              ))}
            />
            {contractTypeInfo?.clauses.length > 0 ? (
              contractTypeInfo?.clauses?.map((clause, index) => {
                const allBlankSpaces = getAllBlankSpaces({
                  clause: clause.clause,
                });
                if (allBlankSpaces !== 0) {
                  return (
                    <FillBlankspacesBox
                      key={clause._id}
                      title={clause.clause.title}
                      clause={clause.clause}
                      indexClause={index}
                      handleBlankspacesClause={handleBlankspacesClause}
                      handleBlankspacesSubclause={handleBlankspacesSubclause}
                      handleBlankspacesSubclauseChild={
                        handleBlankspacesSubclauseChild
                      }
                      handleBlankspacesParagraphs={handleBlankspacesParagraphs}
                    />
                  );
                } else {
                  return <></>;
                }
              })
            ) : (
              <></>
            )}
            <FillBlankspacesBox
              title={"Firmantes"}
              isCompleteCustomBox={handleGetFillBlankspacesSignatories()}
              customBox={
                <div>
                  {contractTypeInfo?.parts?.map((role, indexRole) => (
                    <Container
                      key={`signatories-${indexRole}-${role._id}`}
                      fluid
                      style={{ margin: "3% 0" }}
                      onClick={() => {
                        flashItemSelected(`signatories-${indexRole}`);
                      }}
                    >
                      <Row className="mb-4 mt-4">
                        <Col xs={4} className={"heading"}>
                          {role.name}
                        </Col>
                      </Row>
                      {role?.parts?.map((part, indexPart) => {
                        return (
                          <div key={`signatories-${indexRole}-${indexPart}`}>
                            <Row className="mb-4 mt-4">
                              <Col xs={4} className={"heading"}>
                                {`Parte #${indexPart + 1}-`}
                                {part?.blankspaces[0]?.value}
                              </Col>
                            </Row>

                            {part.signatories.map(
                              (signatory, indexSignatory) => (
                                <div
                                  key={`signatories-${indexRole}-${indexPart}-${indexSignatory}`}
                                >
                                  {part._id && (
                                    <Row className="mb-4 mt-2">
                                      <Col xs={12}>
                                        <Button
                                          variant="contained"
                                          className="custom-input__button__primary-color"
                                          startIcon={
                                            <AccountCircleIcon fontSize="large" />
                                          }
                                          onClick={() => {
                                            setSignatoryType("with-part");
                                            setOpenSelectSignatory(true);
                                            setSignatoryInfo({
                                              indexRole,
                                              indexPart,
                                              signatoryIndex: indexSignatory,
                                              part,
                                            });
                                          }}
                                        >
                                          Cargar firmante desde biblioteca
                                        </Button>
                                      </Col>
                                    </Row>
                                  )}
                                  {signatory?.blankspaces.map(
                                    (blank, indexBlank) => (
                                      <div
                                        key={`${indexRole}-${indexPart}-${indexSignatory}-${blank.index}`}
                                      >
                                        <Row
                                          style={{ margin: "1% 0" }}
                                          className="caption"
                                        >
                                          {blank.label}
                                        </Row>
                                        <Row>
                                          <Form.Group className="mb-3">
                                            <Form.Control
                                              as={"textarea"}
                                              placeholder={`Ingresar el ${blank.label.toLowerCase()}`}
                                              bsPrefix={
                                                "input-group-container__no-icon label"
                                              }
                                              onChange={(e) => {
                                                handleFillBlankspacesSignatories(
                                                  {
                                                    newText: e.target.value,
                                                    indexBlank,
                                                    indexSignatory,
                                                    indexPart,
                                                    indexRole,
                                                    nameBlank: blank.name,
                                                  }
                                                );
                                              }}
                                              value={blank?.value || ""}
                                            />
                                          </Form.Group>
                                        </Row>
                                      </div>
                                    )
                                  )}

                                  <Row className="mb-3">
                                    <Col xs={6} className={"label"}>
                                      Agregar firmante
                                    </Col>
                                    <Col xs={4}></Col>
                                    <Col xs={1}>
                                      <Form.Check
                                        type="switch"
                                        checked={
                                          contractTypeInfo?.parts[indexRole]
                                            ?.parts[indexPart][
                                            indexSignatory + 1
                                          ]
                                        }
                                        style={{ fontSize: "150%" }}
                                        onChange={(e) => {
                                          handleAddSignatory(
                                            indexPart,
                                            indexRole,
                                            indexSignatory + 1
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              )
                            )}
                          </div>
                        );
                      })}
                    </Container>
                  ))}
                  <Container fluid style={{ margin: "3% 0" }}>
                    <Row className="mb-4 mt-4">
                      <Col xs={4} className={"heading"}>
                        Terceros
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={6} className={"label"}>
                        Agregar tercero como firmante
                      </Col>
                      <Col xs={4}></Col>
                      <Col xs={1}>
                        <Form.Check
                          type="switch"
                          checked={contractTypeInfo?.otherSignatories[0]}
                          style={{ fontSize: "150%" }}
                          onChange={(e) => {
                            handleAddExternalSignatory(0);
                          }}
                        />
                      </Col>
                    </Row>
                    {contractTypeInfo?.otherSignatories.map(
                      (item, indexSignatory) => {
                        return (
                          <div
                            onClick={() => {
                              flashItemSelected(`otherSig-${indexSignatory}`);
                            }}
                          >
                            {item?.blankspaces.map((blank, indexBlank) => (
                              <div key={blank.index}>
                                <Row
                                  style={{ margin: "1% 0" }}
                                  className="caption"
                                >
                                  {blank.label}
                                </Row>
                                <Row>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      as={"textarea"}
                                      placeholder={`Ingresar el ${blank.label.toLowerCase()}`}
                                      bsPrefix={
                                        "input-group-container__no-icon label"
                                      }
                                      onChange={(e) => {
                                        handleFillBlankspacesOtherSignatories({
                                          newText: e.target.value,
                                          indexBlank,
                                          indexSignatory,
                                          nameBlank: blank.name,
                                        });
                                      }}
                                      value={blank?.value || ""}
                                    />
                                  </Form.Group>
                                </Row>
                              </div>
                            ))}
                            <Row className="mb-4 mt-2">
                              <Col xs={12}>
                                <Button
                                  variant="contained"
                                  className="custom-input__button__primary-color"
                                  startIcon={
                                    <AccountCircleIcon fontSize="large" />
                                  }
                                  onClick={() => {
                                    setSignatoryType("without-part");
                                    setOpenSelectSignatory(true);
                                    setOtherSignatoryInfo({
                                      signatoryIndex: indexSignatory,
                                    });
                                  }}
                                >
                                  Cargar firmante desde biblioteca
                                </Button>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs={6} className={"label"}>
                                Agregar tercero como firmante
                              </Col>
                              <Col xs={4}></Col>
                              <Col xs={1}>
                                <Form.Check
                                  type="switch"
                                  checked={
                                    contractTypeInfo?.otherSignatories[
                                      indexSignatory + 1
                                    ]
                                  }
                                  style={{ fontSize: "150%" }}
                                  onChange={(e) => {
                                    handleAddExternalSignatory(
                                      indexSignatory + 1
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        );
                      }
                    )}
                  </Container>
                </div>
              }
            />
          </Col>
          <Col
            xs={8}
            style={{ borderTop: "1px solid rgba(230, 233, 239, 1)" }}
            className="custom-accordion__container-headers"
            id={"content-document"}
          >
            <Container fluid>
              <Row
                className="display-large"
                style={{ margin: "2% 0" }}
                id={`name-document`}
              >
                {contractTypeInfo?.name}
              </Row>
              <Row style={{ margin: "2% 0" }}>
                {contractTypeInfo?.parts?.map((role, index) => (
                  <Container
                    key={role._id}
                    className="container_text"
                    id={`part-${index}`}
                  >
                    <Row>
                      <Col
                        style={{ margin: "2% 0" }}
                        className="heading__primary-color"
                      >{`${role.name || "Sin titulo"}`}</Col>
                    </Row>
                    <Row className="container_text__subclause">
                      {role.parts.map((item, indexPart) => {
                        return (
                          <Container
                            key={`${role._id}-${indexPart}`}
                            style={{ padding: "0 0 0 3%" }}
                            className="label  mt-3"
                          >
                            {ReactHtmlParser(item.text)}
                          </Container>
                        );
                      })}
                    </Row>
                  </Container>
                ))}
              </Row>
              <Divider />
              <Row style={{ margin: "2% 0" }}>
                {contractTypeInfo?.clauses.length > 0 ? (
                  contractTypeInfo?.clauses?.map((clause, index) => {
                    const subclauses =
                      clause?.clause?.subclauses || clause?.subclauses;
                    const paragraphs =
                      clause?.clause?.paragraphs || clause?.paragraphs;
                    return (
                      <Row key={clause.clause._id}>
                        <Container className="container_text">
                          <Row id={"title-clause"} style={{ margin: "2% 0" }}>
                            <Col md={4} className="heading__primary-color">{`${
                              index + 1
                            }. ${
                              clause?.title ||
                              clause?.clause?.title ||
                              "Sin titulo"
                            }`}</Col>
                          </Row>
                          <Row
                            className="container_text__clause-text label"
                            style={{ padding: "0 0 0 5%" }}
                            id={`${index + 1}.0.0`}
                          >
                            {ReactHtmlParser(
                              clause?.text || clause?.clause?.text
                            )}
                          </Row>
                        </Container>
                        {subclauses?.length > 0 &&
                          subclauses?.map((sub, index2) => (
                            <Container
                              key={sub.subclause._id}
                              className="container_text__subclause"
                            >
                              <Row style={{ alignItems: "center" }}>
                                <Col
                                  xs={"auto"}
                                  className="heading__primary-color"
                                >
                                  {`${index + 1}.${index2 + 1}. ${
                                    sub?.subclause?.title || ""
                                  }`}
                                </Col>
                              </Row>
                              <Row style={{ alignItems: "center" }}>
                                <Col
                                  className="container_text__clause-text label"
                                  id={`${index}.${index2 + 1}.0`}
                                >
                                  {ReactHtmlParser(sub?.subclause?.text)}
                                </Col>
                              </Row>
                              <Row className="container_text__clause-text label"></Row>
                              {sub?.subclause?.childs?.map((child, index4) => (
                                <Container
                                  key={child._id}
                                  className="container_text__subclause"
                                >
                                  <Row>
                                    <Col className="heading__primary-color">{`${
                                      index + 1
                                    }.${index2 + 1}.${index4 + 1}. ${
                                      child.title || ""
                                    }`}</Col>
                                  </Row>
                                  <Row
                                    className="container_text__clause-text label"
                                    id={`${index}.${index2 + 1}.${index4 + 1}`}
                                  >
                                    {ReactHtmlParser(child.text)}
                                  </Row>
                                </Container>
                              ))}
                            </Container>
                          ))}
                        {paragraphs?.length > 0 &&
                          paragraphs?.map((para, index3) => (
                            <Container
                              key={para._id}
                              className="container_text__subclause"
                            >
                              <Row>
                                <Col className="heading__primary-color">{`Parágrafo ${
                                  NUMBERINGWORDSO[index3 + 1]
                                } ${para?.paragraph?.title || ""}`}</Col>
                              </Row>
                              <Row
                                className="container_text__clause-text label"
                                id={`${index}.0.${index3 + 1}`}
                              >
                                {ReactHtmlParser(para.paragraph.text)}
                              </Row>
                            </Container>
                          ))}
                      </Row>
                    );
                  })
                ) : (
                  <p className="container_text__clause-text label">
                    Sin cláusulas.
                  </p>
                )}
              </Row>
              <Divider />
              <Row style={{ margin: "2% 0" }}>
                {contractTypeInfo?.parts?.map((role, indexRole) => (
                  <Container
                    key={role._id}
                    className="container_text"
                    id={`signatories-${indexRole}`}
                  >
                    <Row style={{ margin: "2% 0" }}>
                      <Col className="heading__primary-color">{`${
                        indexRole + 1
                      }. ${role.name || "sin titulo"}`}</Col>
                    </Row>
                    <Row style={{ padding: "0 0 0 5%" }}>
                      {role?.parts?.length > 0 ? (
                        role?.parts?.map((part, indexPart) =>
                          part?.signatories?.map(
                            (signatory, indexSignatory) => (
                              <p
                                key={`signatories-${indexRole}-${indexPart}-${indexSignatory}`}
                                className="label"
                              >
                                {ReactHtmlParser(signatory.text)}
                              </p>
                            )
                          )
                        )
                      ) : (
                        <p className="label">Sin firmas</p>
                      )}
                    </Row>
                  </Container>
                ))}
              </Row>

              <Row style={{ margin: "2% 0" }}>
                <Container className="container_text">
                  <Row style={{ margin: "2% 0" }}>
                    <Col className="heading__primary-color">
                      Otros firmantes
                    </Col>
                  </Row>
                  <Row
                    style={{ padding: "0 0 0 5%" }}
                    className="container_text__clause-text label"
                  >
                    {contractTypeInfo?.otherSignatories?.length > 0 ? (
                      contractTypeInfo?.otherSignatories?.map((sig, index) => {
                        return (
                          <Col
                            xs={12}
                            className="mt-3"
                            id={`otherSig-${index}`}
                            key={`otherSig-${index}`}
                          >
                            {ReactHtmlParser(sig.text)}
                          </Col>
                        );
                      })
                    ) : (
                      <p className="label">Sin firmas</p>
                    )}
                  </Row>
                </Container>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row className="sidebar__bottom__container ">
          <Col xs={3}>
            <ProgressBar capacity={100} used={countGeneralBlankspaces} />
          </Col>

          <Col md={2}>
            <Button
              variant="contained"
              startIcon={<PaletteOutlined />}
              className="custom-input__button__secondary-color"
              onClick={handleApplyPersonalization}
            >
              {selectedHeader ? "Cambiar Membrete" : "Membretes"}
            </Button>
          </Col>
          <Col xs={1}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(-2);
                Mixpanel.track("Solicitante Btn Descartar", {
                  email: payloadToken?.email,
                  companyName: payloadToken?.companyName,
                });
              }}
              className={"custom-input__button__secondary-color"}
            >
              Descartar
            </Button>
          </Col>
          <Col xs={2}>
            <WordCreator
              title={contractTypeInfo?.name}
              parts={contractTypeInfo?.parts}
              clauses={contractTypeInfo?.clauses}
              message={"Descargar word"}
              numberingType={"number"}
              selectedHeader={selectedHeader}
              setIsLoading={setIsLoading}
              mixPanelTrack="Solicitante Btn Descargar Word"
              otherSignatories={contractTypeInfo?.otherSignatories}
            />
          </Col>
        </Row>
      </Container>
    );
  }
};
