export const CODES = {
  COD_RESPONSE_SUCCESS: 0,

  //Response app error codes
  COD_RESPONSE_ERROR_CREATE: 1001,
  COD_RESPONSE_ERROR_UPDATE: 1002,
  COD_RESPONSE_ERROR_DELETE: 1003,
  COD_RESPONSE_ERROR_FIND: 1004,
  COD_RESPONSE_ERROR_LIST: 1005,
  COD_RESPONSE_ERROR_LOGIN: 1006,
  COD_RESPONSE_ERROR_UNAUTHORIZED: 1007,
  COD_RESPONSE_ERROR_SHOW: 1008,
  COD_RESPONSE_ERROR_GET_WITH_FILTERS: 1009,
  COD_RESPONSE_ERROR_ALREADY_EXISTS: 1010,
  COD_RESPONSE_ERROR_VALIDATE: 1011,
  COD_RESPONSE_ERROR_SEND_VERIFICATION_CODE: 1012,
  COD_RESPONSE_ERROR_CHANGE_PASSWORD: 1013,
  COD_RESPONSE_ERROR_USER_DOES_NOT_EXIST: 1014,
  COD_RESPONSE_VALIDATION_TOKEN: 1015,
  COD_RESPONSE_MISSING_TOKEN: 1016,
  COD_RESPONSE_ERROR_NOT_FOUND_IN_DATABASE: 1017,
  COD_RESPONSE_ERROR_LOAD_DEFAULT_DATA: 1018,
  COD_RESPONSE_ERROR_ASSOCIATE: 1019,
  COD_RESPONSE_ERROR_TOKEN_EXPIRED: 1020,
  COD_RESPONSE_ERROR_MAXIMUM_ATTEMPTS_ALLOWED: 1021,
  COD_RESPONSE_ERROR_INCORRECT_CREDENTIALS: 1022, //CONTRASEÑA Y USER MAL
  COD_RESPONSE_ERROR_NEW_PASSWORD_REQUIRED: 1023, //NUEVA CONTRASEÑA
  COD_RESPONSE_ERROR_UNCONFIRMED_USER: 1024, //USER NO CONFIRMADO
  COD_RESPONSE_ERROR_DISABLED_USER: 1025, //USER BLOQUEADO
  COD_RESPONSE_VALIDATION_USER_LOGGED: 1030, //USER LOGEADO
  COD_RESPONSE_VALIDATION_MFA_REQUIRED: 1032, // MFA CODE REQUIRED
  COD_RESPONSE_VALIDATION_MFA_BAD: 1033, // MFA CODE DONT MATCH
  COD_RESPONSE_ERROR_LOGIN_FEDERATED_IN_PLATFORM: 1035,
  COD_RESPONSE_ERROR_SERVER_IN_MAINTENANCE: 1036,
  COD_RESPONSE_ERROR_LIMIT_PLAN_USED: 1037,
  COD_RESPONSE_REGISTER_NEW_DEVICE: 1038,

  //HTTP Response codes
  COD_RESPONSE_HTTP_OK: 200,
  COD_RESPONSE_HTTP_CREATED: 201,
  COD_RESPONSE_HTTP_BAD_REQUEST: 400,
  COD_RESPONSE_HTTP_UNAUTHORIZED: 401,
  COD_RESPONSE_HTTP_FORBIDDEN: 403,
  COD_RESPONSE_HTTP_NOT_FOUND: 404,
  COD_RESPONSE_HTTP_ERROR: 500,
  COD_RESPONSE_HTTP_ACCEPTED: 202,

  //Color Ids
  COD_LIGHT_ORANGE: 100,
  COD_LIGHT_PURPLE: 200,
  COD_LIGHT_GREEN: 300,
  COD_LIGHT_RED: 400,
  COD_LIGHT_BLUE: 500,
};

export const projectConstants = {
  allColorIds: [
    CODES.COD_LIGHT_ORANGE,
    CODES.COD_LIGHT_PURPLE,
    CODES.COD_LIGHT_GREEN,
    CODES.COD_LIGHT_RED,
  ],
};
