import { SvgIcon } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const Breadcrum = ({ state, setState, overRideBack }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (overRideBack) {
      // overRideBack();
    }
    if (state) {
      setState(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <Container style={{ padding: "1% 0%" }}>
      <Row xs={"auto"}>
        <Col onClick={handleClick}>
          <SvgIcon
            style={{
              width: "2.4rem",
              height: "2.4rem",
              border: "1px solid",
              borderRadius: "4px",
              backgroundColor: "#E7F9FC",
              color: "#C5C7D0",
              cursor: "pointer",
            }}
          >
            <ArrowBackIcon />
          </SvgIcon>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="body-content__text-disabled">Atrás</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Breadcrum;
