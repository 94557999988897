import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import SubModalGeneral from "./SubModalGeneral";

const ModalDecision = ({ title, message, agreeText, disagreeText, handleAgree, handleDisagree, onClose, open }) => {

    const handleClose = () => {
        onClose();
    }

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth='sm'
                onClose={handleClose}
                open={open}
                aria-labelledby="customized-dialog-title"
            >
                <SubModalGeneral onClose={handleClose}>
                    <label className="heading__primary-color">{title}</label>
                </SubModalGeneral>
                <DialogContent className="caption" dividers>
                    {message}
                </DialogContent>
                <DialogActions>
                    <Row>
                        <Col >
                            <Button
                                variant="text"
                                className="custom-input__button__secondary-color__forced"
                                onClick={handleDisagree}
                            >
                                {disagreeText}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="contained"
                                className="custom-input__button__primary-color__forced"
                                onClick={handleAgree}
                            >
                                {agreeText}
                            </Button>
                        </Col>
                    </Row>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalDecision;