import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import useIdle from "../../utils/useIdleTimer";
import ModalInactivity from "../../components/Modals/ModalInactivity";
import { useDispatch } from "react-redux";
import { setStorageInfo } from "../../reducers/storageSlice";
import { validateEnablePlan } from "../../services/Storage/StorageServices";

const Layout = () => {
  const warning = () => {
    setOpenInactivity(true);
  };
  const { isIdle, setIsIdle } = useIdle({ onIdle: warning, idleTime: 60 });
  const [openInactivity, setOpenInactivity] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const isIdle = localStorage.getItem("isIdle");
    if (isIdle === "true") {
      setIsIdle(true);
      setOpenInactivity(true);
    }
  }, []);

  const checkStorage = async () => {
    try {
      const storageInfo = await validateEnablePlan();
      dispatch(setStorageInfo(storageInfo));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkStorage();
    const asyncCheckStorage = async () => {
      try {
        await checkStorage();
      } catch (error) {
        console.error(error)
      }
    }
    const interval = setInterval(asyncCheckStorage, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <Container
      fluid
      style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
    >
      <Row style={{ height: "inherit" }}>
        <Col md={2} className="sidebar-base">
          {/**Padding crítico pero necesario */}
          <Sidebar />
        </Col>
        <Col>
          {/* // <-- nested routes rendered here --> */}
          {isIdle ? (
            <ModalInactivity
              isOpen={openInactivity}
              setIsOpen={setOpenInactivity}
            />
          ) : (
            <></>
          )}
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
