import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";
import { v4 as uuid } from "uuid";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button } from "@mui/material";
import LoadingContent from "../../components/LoadingContent";
import SectionTitle from "../../components/sectionTitle";
import Breadcrum from "../../pages/parts/breadcrum";
import { REGEXP } from "../../utils/regexp";
import {
  CreateNewRequestCarvajal,
  getCompaniesList,
  getRequestCarvajal,
} from "../../services/CarvServices/CarvajalServices";
import { CODES } from "../../utils/codes";
import { convertToPdfService } from "../../services/ConvertDocs/convertWordToPdf";
import ModalInfo from "../../components/Modals/ModalInfo";
import { setStatusRequest } from "../../reducers/requestSlice";
import { getCompanyPlan } from "../../reducers/storageSlice";
import { CustomToolTip } from "../../components/Tooltip/Tooltip";
import { Mixpanel } from "../../utils/mixPanel";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import { ModalSelectPartForRequest } from "../Create/selectPartForRequest";
import { QUESTION_REQUEST_EXTERNAL } from "./request.utils";
import { checkCERLRequest } from "../../services/parts/partsServices";

const CreateRequest = () => {
  const navigate = useNavigate();
  const companyPlan = useSelector(getCompanyPlan);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState();
  const [fileNames, setFileNames] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [formDetails, setformDetails] = useState([]);
  const [requestSelected, setRequestSelected] = useState({});
  const [requestType, setRequestType] = useState("");
  const [tempNameRequest, setTempNameRequest] = useState("");
  const [requestSubtype, setRequestSubtype] = useState([]);
  const [isReview, setReview] = useState("");
  const [isOpenModalInfoResponse, setIsOpenModalInfoResponse] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [showForm, setForm] = useState(false);
  const [disableUpload, setDisableUpload] = useState(false);
  const [openSelectPart, setOpenSelectPart] = useState({
    open: false,
    idxSection: null,
    index: null,
  });

  const findIndexSectionForQuestionParts = (fields) => {
    if (!fields?.length) return null;
    const result = {
      indexAdress: fields.findIndex(
        (field) => field.value === QUESTION_REQUEST_EXTERNAL.ADDRESS
      ),
      indexAdressAgentLegal: fields.findIndex(
        (field) => field.value === QUESTION_REQUEST_EXTERNAL.ADDRESS_AGENT_LEGAL
      ),
      indexCountry: fields.findIndex(
        (field) => field.value === QUESTION_REQUEST_EXTERNAL.COUNTRY
      ),
      indexDocumentNumber: fields.findIndex(
        (field) => field.value === QUESTION_REQUEST_EXTERNAL.DOCUMENT_NUMBER
      ),
      indexDocumentNumberAgentLegal: fields.findIndex(
        (field) =>
          field.value === QUESTION_REQUEST_EXTERNAL.DOCUMENT_NUMBER_AGENT_LEGAL
      ),
      indexEmail: fields.findIndex(
        (field) => field.value === QUESTION_REQUEST_EXTERNAL.EMAIL
      ),
      indexName: fields.findIndex(
        (field) => field.value === QUESTION_REQUEST_EXTERNAL.NAME
      ),
      indexNameAgentLegal: fields.findIndex(
        (field) => field.value === QUESTION_REQUEST_EXTERNAL.NAME_AGENT_LEGAL
      ),
      indexTypePerson: fields.findIndex(
        (field) => field.value === QUESTION_REQUEST_EXTERNAL.TYPE_PERSON
      ),
    };
    return result;
  };

  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    setError,
    unregister,
    formState: { errors },
  } = useForm({});

  const selectPart = (part) => {
    const fieldsSection =
      requestSelected?.sections[openSelectPart.idxSection]?.fields;
    const indexesQuestions = findIndexSectionForQuestionParts(fieldsSection);

    if (!fieldsSection.length || !indexesQuestions || !part) return;

    //Definimos los nombres que se registran en el useForm
    const registerName = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexName}.answer`;
    const registerDocumentNumber = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexDocumentNumber}.answer`;
    const registerAdress = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexAdress}.answer`;
    const registerCountry = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexCountry}.answer`;
    const registerEmail = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexEmail}.answer`;
    const registerNameAgentLegalCheck = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexNameAgentLegal}.check`;
    const registerDocumentAgentLegalCheck = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexDocumentNumberAgentLegal}.check`;
    const registerAdressAgentLegalCheck = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexAdressAgentLegal}.check`;
    const registerNameAgentLegalText = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexNameAgentLegal}.answer`;
    const registerDocumentAgentLegalText = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexDocumentNumberAgentLegal}.answer`;
    const registerAdressAgentLegalText = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexAdressAgentLegal}.answer`;
    const registerTypePerson = `section.${openSelectPart.idxSection}.answers.${indexesQuestions.indexTypePerson}.answer`;

    reset({
      applicantCompany: getValues("applicantCompany"),
      [registerName]: "",
      [registerDocumentNumber]: "",
      [registerAdress]: "",
      [registerCountry]: "",
      [registerEmail]: "",
      [registerTypePerson]: "",
      [registerNameAgentLegalCheck]: "",
      [registerDocumentAgentLegalCheck]: "",
      [registerAdressAgentLegalCheck]: "",
      [registerNameAgentLegalText]: "",
      [registerDocumentAgentLegalText]: "",
      [registerAdressAgentLegalText]: "",
      subtype: getValues("subtype"),
    });
    let documentNumber;

    //Tipo de persona
    const isLegalPart = part.typePart === "JURIDICA" ? "Sí" : "No";

    //Cargamos los datos al useForm
    const name =
      part?.comercialName ||
      `${part?.firstName || ""} ${part?.secondName || ""} ${
        part?.firstSurname || ""
      } ${part?.secondSurname || ""}`;
    const adress = part?.city || "";
    const country = part?.country || "Colombia";
    const email = part?.email || "";
    setValue(registerTypePerson, isLegalPart);
    setValue(registerName, name);
    setValue(registerAdress, adress);
    setValue(registerCountry, country);
    setValue(registerEmail, email);

    if (part.typePart === "NATURAL") {
      documentNumber = part?.documentNumber;
      setValue(registerDocumentNumber, documentNumber);
      setValue(registerNameAgentLegalCheck, "No aplica");
      setValue(registerDocumentAgentLegalCheck, "No aplica");
      setValue(registerAdressAgentLegalCheck, "No aplica");
    } else {
      documentNumber = part?.business?.nit;
      const nameAgentLegal = `${part?.legalRepresentative?.firstName || ""} ${
        part?.legalRepresentative?.secondName || ""
      } ${part?.legalRepresentative?.firstSurname || ""} ${
        part?.legalRepresentative?.secondSurname || ""
      }`;
      const documentAgentLegal = `${
        part?.legalRepresentative?.documentType || ""
      } ${part?.legalRepresentative?.documentNumber || ""}`;
      const addressAgentLegal = "";
      setValue(registerNameAgentLegalText, nameAgentLegal);
      setValue(registerDocumentAgentLegalText, documentAgentLegal);
      setValue(registerAdressAgentLegalText, addressAgentLegal);
    }
    setValue(registerDocumentNumber, documentNumber);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "otherDocuments",
  });
  const MAX_SIZE_FILE = 15728640;

  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  const COLUMNS_DOCS = [
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="1"
        >
          Nombre del documento
        </label>,
      ],
      field: "title",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="4"
        >
          Acciones
        </label>,
      ],
      field: "action",
    },
  ];

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setDisableUpload(true);
      }
    }
  }, [companyPlan]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { company } = JSON.parse(localStorage.getItem("payloadToken"));

        const service = await getCompaniesList();
        if (service?.status === CODES.COD_RESPONSE_HTTP_OK) {
          const { list } = service.data.responseMessage;
          if (list.length === 1) {
            setValue("applicantCompany", list[0]);
          }
          setCompanyList(list);
        }
        const obj = {
          formType: "Formulario estándar",
          companyId: company,
        };

        const getForms = await getRequestCarvajal(obj);
        if (getForms.status) {
          if (getForms.status === CODES.COD_RESPONSE_HTTP_OK) {
            const { responseMessage } = getForms.data;
            setformDetails(responseMessage);
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const buildAnswers = (data) => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    const { company, userId } = payloadToken;
    const { _id: corporateUnit } = JSON.parse(
      localStorage.getItem("corporateUnit")
    );

    let arrayAnswers = [];
    for (const arrays of data.section) {
      for (const answerObj of arrays.answers) {
        let answer = { ...answerObj };
        if (answer["subType"]) {
          answer["answer"] = answer["answer"] + " - " + answer["subType"];
        }
        delete answer["subType"];
        arrayAnswers = [...arrayAnswers, answer];
      }
    }

    let requiredDocumentsObj = [];
    const requiredDocsArray = data?.requiredDocs || [];
    for (const document of requiredDocsArray) {
      if (document?.file?.[0]) {
        requiredDocumentsObj = [
          ...requiredDocumentsObj,
          { name: document.name, file: document.file[0] },
        ];
      }
    }

    let othersDocumentsObj = [];

    const otherDocumentsArrays = data?.otherDocuments || [];

    for (const document of otherDocumentsArrays) {
      if (document[0]) {
        othersDocumentsObj = [...othersDocumentsObj, document[0]];
      }
    }

    return {
      companyId: company,
      requestSubject: data.name,
      applicantId: userId,
      requestType: requestType,
      requestAnswers: arrayAnswers,
      requiredDocuments: requiredDocumentsObj,
      applicantCompany: data.applicantCompany,
      applicantDocuments: othersDocumentsObj,
      requestSubtype: data.subtype,
      requestTypeRedaction: data.typeRequest,
      corporateUnit,
    };
  };

  const handleCreateRequest = async (data) => {
    try {
      setIsLoading(true);

      const dataObj = buildAnswers(data);

      Mixpanel.track("Solicitante Btn Crear Solicitud", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
        requestType: dataObj.requestType,
        requestSubtype: requestSubtype?.find(
          (item) => item._id === dataObj.requestSubtype
        )?.name,
        requestTypeRedaction: dataObj.requestTypeRedaction,
      });

      const corporateUnit = JSON.parse(localStorage.getItem("corporateUnit"));

      const cerlFile = dataObj?.requiredDocuments.find(
        (document) =>
          document.name ===
          "Certificado de existencia y representacion de la otra parte o su equivalente"
      );

      if (cerlFile?.name) {
        const companyId = localStorage.getItem("company");
        let service = {};
        const checkObject = {
          file: cerlFile.file,
          associatedCorporateUnits: [corporateUnit._id],
          company: companyId,
        };
        const serviceValidation = await checkCERLRequest(checkObject);
        if (serviceValidation.status) {
          if (serviceValidation.status === CODES.COD_RESPONSE_HTTP_OK) {
            service = await CreateNewRequestCarvajal(dataObj);
            if (service.status) {
              if (
                service.status === CODES.COD_RESPONSE_HTTP_CREATED &&
                service.data.responseCode !== CODES.COD_RESPONSE_ERROR_FIND
              ) {
                const id = service?.data?.responseMessage?.data?.consecutive;
                navigate(`/external/view/${id}`);
              }
            }
          } else {
            setResponseData(serviceValidation);
            setIsOpenModalInfoResponse(true);
            dispatch(setStatusRequest({ status: "fetch" }));
          }
        }
      } else {
        const service = await CreateNewRequestCarvajal(dataObj);
        if (service.status) {
          if (
            service.status === CODES.COD_RESPONSE_HTTP_CREATED &&
            service.data.responseCode !== CODES.COD_RESPONSE_ERROR_FIND
          ) {
            const id = service?.data?.responseMessage?.data?.consecutive;
            navigate(`/external/view/${id}`);
          }
        }
        setResponseData(service);
        setIsOpenModalInfoResponse(true);
        dispatch(setStatusRequest({ status: "fetch" }));
      }
    } catch (error) {
      console.log("============== Handle Create Request Error ==============");
      console.error(error);
      console.log(
        "============== Handle Create Request Error Finish =============="
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = (index) => {
    const getFile = getValues(`requiredDocs.${index}`);
    const file = getFile?.file?.[0];
    if (file) {
      const blob = new Blob([file]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    }
  };
  const handleUploadOtherDocument = () => {
    append({});
  };
  const handleDeleteOtherDocument = (index) => {
    remove(index);
  };
  const handlePreviewPdf = async (index) => {
    try {
      setIsLoading(true);
      const file = getValues(`requiredDocs.${index}`);
      const response = await convertToPdfService(file.file[0]);
      if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
        const data = response.data.responseMessage.data.buffer;
        const file = URL.createObjectURL(
          new Blob([new Uint8Array(data.data).buffer], {
            type: "application/pdf",
          })
        );
        window.open(file);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const isValidReview = ({ doc, typeReview }) =>
    !doc.originalFilename.includes("revisar") || typeReview === "Revisión";

  useEffect(() => {
    if (requestSelected) {
      let filterData = [];
      if (requestSelected?.requiredDocuments?.length) {
        filterData = requestSelected?.requiredDocuments.filter((doc) =>
          isValidReview({ doc, typeReview: isReview })
        );
      }
      setDataTable({
        columns: COLUMNS_DOCS,
        rows: filterData.map((item, index) => {
          const isValid = isValidReview({ doc: item, typeReview: isReview });
          if (isReview !== "Consulta Jurídica" && isValid) {
            return {
              title: (
                <Form.Label
                  {...register(`requiredDocs.${index}.name`, {
                    value: item.originalFilename,
                  })}
                >
                  {item.originalFilename}
                </Form.Label>
              ),
              action: (
                <>
                  <Form.Control
                    id={`requiredDocs.${index}.file`}
                    className="input-upload"
                    disabled={disableUpload}
                    {...register(`requiredDocs.${index}.file`, {
                      required: false,
                      validate: (value) =>
                        value?.[0] && value[0].size <= 78643200,
                    })}
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        const newArray = [...fileNames];
                        newArray[index] = e.target.files[0].name;
                        setFileNames(newArray);
                      } else {
                        const newArray = [...fileNames];
                        newArray[index] = "";
                        setFileNames(newArray);
                      }
                    }}
                    type="file"
                  />
                  {fileNames[index] && (
                    <Form.Label
                      className="input-upload__button_primary_color"
                      onClick={() => handleDownload(index)}
                      style={{ marginRight: "1rem" }}
                    >
                      <DownloadIcon fontSize="large" />
                    </Form.Label>
                  )}
                  {disableUpload ? (
                    <CustomToolTip
                      direction="top"
                      message="La compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el usuario administrador"
                    >
                      <Form.Label
                        className={"input-upload__button__disabled"}
                        disabled={true}
                        style={{ marginRight: "1rem" }}
                      >
                        <CloudUploadOutlinedIcon fontSize="large" />
                        {" Cargar"}
                      </Form.Label>
                    </CustomToolTip>
                  ) : (
                    <Form.Label
                      className={"input-upload__button"}
                      htmlFor={`requiredDocs.${index}.file`}
                      style={{ marginRight: "1rem" }}
                    >
                      <CloudUploadOutlinedIcon fontSize="large" />
                      {" Cargar"}
                    </Form.Label>
                  )}

                  {fileNames[index] && (
                    <Form.Label
                      className="input-upload__button_primary_color"
                      onClick={() => {
                        handlePreviewPdf(index);
                      }}
                    >
                      <VisibilityIcon fontSize="large" />
                    </Form.Label>
                  )}
                </>
              ),
            };
          }
        }),
      });
    }
  }, [fileNames, requestSelected, isReview]);

  const handleRequestTypeChange = ({ target }) => {
    const { value } = target;

    const requestSelected = formDetails.find(
      (requestDetail) => requestDetail.name === value
    );
    setRequestSelected({});
    setRequestSubtype(requestSelected?.subtypes);
    setRequestType(value);
    reset();

    setValue("type", value);
    setValue("name", tempNameRequest);
    setValue("subtype", "");
    setValue(
      "applicantCompany",
      companyList.length === 1 ? companyList[0] : ""
    );
    setValue("typeRequest", isReview);
    setFileNames([]);
    if (requestSelected?.subtypes?.length === 1) {
      setRequestSelected(requestSelected.subtypes[0]);
      setValue("subtype", requestSelected.subtypes[0]._id);
    }
  };

  const handleRequestSubTypeChange = ({ target }) => {
    const { value } = target;

    const requestSelected = requestSubtype.find(
      (requestDetail) => requestDetail._id === value
    );
    reset();
    setRequestSelected(requestSelected);
    setValue("name", tempNameRequest);
    setValue("subtype", requestSelected._id);
    setValue(
      "applicantCompany",
      companyList.length === 1 ? companyList[0] : ""
    );
    setValue("typeRequest", isReview);
    setFileNames([]);
  };

  useEffect(() => {
    if (isReview === "Consulta Jurídica") {
      setForm(false);
      handleRequestTypeChange({ target: { value: isReview } });
    } else {
      setRequestType("");
      setForm(true);
    }
  }, [isReview]);

  const handleSelectChange = (event) => {
    setReview(event.target.value);
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      {/* Headers */}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Crear solicitud"} />
      </Row>
      {/* Formularios */}
      <Row>
        <Form onSubmit={handleSubmit(handleCreateRequest)}>
          <Form.Group>
            <br />
            <br />
            <h1 style={{ color: "#00374F" }} className="form__title">
              Detalles de la solicitud
            </h1>
            <Row style={{ alignItems: "center" }}>
              <Col xs={"auto"}>
                <Form.Label className="form__label">
                  Asunto de la solicitud:
                </Form.Label>
                <br></br>
                <span className="caption__primary-color">
                  Máximo 60 caracteres
                </span>
              </Col>
              <Col md={5}>
                <Form.Control
                  style={{ width: "90%" }}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                    maxLength: {
                      message: "El asunto debe tener máximo 60 caracteres",
                      value: 60,
                    },
                  })}
                  maxLength="60"
                  type="text"
                  bsPrefix={
                    errors?.name
                      ? "form__input input-group-container__error"
                      : "form__input input-group-container"
                  }
                  placeholder={
                    "Describe de forma única tu solicitud en pocas palabras"
                  }
                  onChange={(e) => {
                    setTempNameRequest(e.target.value);
                  }}
                />
                <span className="caption custom-input__error">
                  {errors?.name?.message}
                </span>
              </Col>
            </Row>
            <br />
            <Row style={{ alignItems: "center" }}>
              <Col xs={"auto"}>
                <Form.Label className="form__label">
                  Tipo de solicitud:
                </Form.Label>
              </Col>
              <Col md={3}>
                <Form.Select
                  as="select"
                  value={isReview}
                  placeholder="Tipo de solicitud"
                  bsPrefix={
                    errors?.typeRequest
                      ? "input-group-container__error form__input"
                      : "form__input"
                  }
                  {...register("typeRequest", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                  })}
                  onChange={handleSelectChange}
                >
                  <option className="label" value="" disabled>
                    Tipo de solicitud
                  </option>
                  <option className="label" value="Redacción">
                    Crear
                  </option>
                  <option className="label" value="Revisión">
                    Revisar
                  </option>
                  <option className="label" value="Consulta Jurídica">
                    Consulta jurídica
                  </option>
                </Form.Select>
                <span className="caption custom-input__error">
                  {errors?.typeRequest?.message}
                </span>
              </Col>
            </Row>
            {showForm && (
              <>
                <br />
                <Row style={{ alignItems: "center" }}>
                  <Col xs={"auto"}>
                    <Form.Label className="form__label">
                      Tipo de documento:
                    </Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Select
                      /* className="label mb-4" */
                      as="select"
                      value={requestType}
                      placeholder="Tipo de documento"
                      bsPrefix={
                        errors?.type
                          ? "input-group-container__error form__input"
                          : "form__input"
                      }
                      {...register("type", {
                        required: {
                          value: true,
                          message: "*Este campo es obligatorio",
                        },
                      })}
                      onChange={handleRequestTypeChange}
                    >
                      <option className="label" value="" disabled>
                        Tipo de documento
                      </option>
                      {formDetails
                        .filter(
                          (formDetail) =>
                            formDetail.name !== "Consulta Jurídica"
                        )
                        .map((formDetail) => (
                          <option key={formDetail.name} value={formDetail.name}>
                            {formDetail.name}
                          </option>
                        ))}
                    </Form.Select>
                    <span className="caption custom-input__error">
                      {errors?.type?.message}
                    </span>
                  </Col>
                </Row>
                <br />
                <Row style={{ alignItems: "center" }}>
                  {requestSubtype.length === 1 ? (
                    <></>
                  ) : (
                    <>
                      <Col xs={"auto"}>
                        <Form.Label className="form__label">
                          Subtipo de documento:
                        </Form.Label>
                      </Col>
                      <Col md={3}>
                        <Form.Select
                          as="select"
                          disabled={!requestSubtype}
                          placeholder="Subtipo de documento"
                          bsPrefix={
                            errors?.subtype
                              ? "input-group-container__error form__input"
                              : "form__input"
                          }
                          {...register("subtype", {
                            required: {
                              value: true,
                              message: "*Este campo es obligatorio",
                            },
                          })}
                          onChange={handleRequestSubTypeChange}
                        >
                          <option className="label" value="" disabled>
                            Subtipo de documento
                          </option>
                          {requestSubtype.map((subtype, index) => (
                            <option
                              key={subtype.name}
                              className="label"
                              value={subtype._id}
                            >
                              {subtype.name}
                            </option>
                          ))}
                        </Form.Select>
                        <span className="caption custom-input__error">
                          {errors?.subtype?.message}
                        </span>
                      </Col>
                    </>
                  )}
                  <Col xs={"auto"}>
                    <p className="form__label">
                      <a
                        style={{ color: "#17B4BC" }}
                        href={"/external/type"}
                        target="_blank"
                      >
                        {`Conoce más `}
                      </a>
                      sobre los subtipos
                    </p>
                  </Col>
                </Row>
              </>
            )}

            <br />
            <Row style={{ alignItems: "center" }}>
              <Col xs={"auto"}>
                <Form.Label className="form__label">
                  Compañía asociada:
                </Form.Label>
              </Col>
              <Col md={3}>
                <Form.Select
                  as="select"
                  defaultValue={""}
                  disabled={companyList.length === 1}
                  bsPrefix={
                    errors?.applicantCompany
                      ? "input-group-container__error form__input"
                      : "form__input"
                  }
                  {...register("applicantCompany", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                  })}
                >
                  <option className="label" value="" disabled>
                    Compañía asociada
                  </option>
                  {companyList?.length > 0 &&
                    companyList.map((company) => (
                      <option key={company} value={company}>
                        {company}
                      </option>
                    ))}
                </Form.Select>
                <span className="caption custom-input__error">
                  {errors?.applicantCompany?.message}
                </span>
              </Col>
            </Row>
            <br />
            <Row className="heading" style={{ paddingLeft: "0.5%" }}>
              Formulario para el documento seleccionado
            </Row>
            <br />
            {
              <>
                {/* Inicio de formularios dinamicos*/}
                {requestSelected?.sections?.length > 0 &&
                  requestSelected?.sections.map((section, idxSection) => (
                    <div key={section?.name}>
                      {/* Título del formulario */}
                      <Row>
                        <h1
                          style={{ color: "#00374F" }}
                          className="form__title"
                        >
                          {section?.name}
                        </h1>
                      </Row>
                      {section?.name === "Partes" && (
                        <>
                          <Row>
                            <Col xs={"auto"}>
                              <Button
                                type="button"
                                variant="contained"
                                startIcon={
                                  <LocalLibraryOutlinedIcon fontSize="large" />
                                }
                                className="custom-input__button__primary-color"
                                onClick={() =>
                                  setOpenSelectPart({ open: true, idxSection })
                                }
                              >
                                Elegir desde biblioteca
                              </Button>
                            </Col>
                          </Row>
                          <br />
                        </>
                      )}
                      {/* Secciones de formularios */}
                      {section?.fields?.length > 0 &&
                        section?.fields.map((field, index) => {
                          const propsForms = {
                            register: register,
                            unregister: unregister,
                            setValue: setValue,
                            setError: setError,
                            watch: watch,
                            errors: errors,
                            field: field,
                            index: index,
                            idxSection: idxSection,
                          };
                          return (
                            <div key={`${field?.value} ${field?.type}`}>
                              <Row style={{ alignItems: "center" }}>
                                {field.type === "list" && (
                                  <FormFieldList {...propsForms} />
                                )}
                                {field.type === "input" && (
                                  <FormFieldInput {...propsForms} />
                                )}
                                {field.type === "list-input" && (
                                  <FormFieldListInput {...propsForms} />
                                )}
                                {field.type === "list-checkbox" && (
                                  <FormFieldListCheckbox {...propsForms} />
                                )}
                                {field.type === "input-checkbox" && (
                                  <FormFieldInputCheckbox {...propsForms} />
                                )}
                                {field.type === "list-input-checkbox" && (
                                  <FormFieldListInputCheckbox {...propsForms} />
                                )}
                                {field.type === "date" && (
                                  <FormFieldDate {...propsForms} />
                                )}
                                {field.type === "date-checkbox" && (
                                  <FormFieldDateCheckbox {...propsForms} />
                                )}
                              </Row>
                              <br />
                            </div>
                          );
                        })}
                      <br />
                    </div>
                  ))}
              </>
            }
            <br />
            {/* Tabla de documentos */}
            {dataTable?.rows?.length > 0 && (
              <>
                <Row>
                  <h1 style={{ color: "#00374F" }} className="form__title">
                    Documentos de la solicitud
                  </h1>
                </Row>
                <Row className="data-table dt-list-items">
                  <MDBDataTableV5
                    sortable={false}
                    paging={false}
                    hover
                    searchBottom={false}
                    entries={20}
                    data={dataTable}
                    infoLabel={["Mostrando", "a", "de", "documentos"]}
                    noRecordsFoundLabel={
                      "No hay documentos para subir, seleccione un tipo de solicitud."
                    }
                  />
                  <span className="caption custom-input__error">
                    {errors?.requiredDocs &&
                      "Revise el peso máximo de los archivos (75MB) antes de guardar la solicitud"}
                  </span>
                </Row>
                <br />
              </>
            )}
            {/* Otros documentos */}
            <Row>
              <h1 style={{ color: "#00374F" }} className="form__title">
                Otros documentos
              </h1>
            </Row>
            {fields.map((item, index) => {
              const file =
                watch(`otherDocuments.${index}`) &&
                watch(`otherDocuments.${index}`)[0];
              return (
                <div key={item.id}>
                  <Row>
                    <Col xs={"auto"}>
                      <Form.Control
                        id={`otherDocuments.${index}`}
                        className="input-upload"
                        {...register(`otherDocuments.${index}`, {
                          validate: {
                            acceptedFormats: (value) => {
                              if (!value[0]) return "*Debe cargar un archivo";
                              const acceptedFormats = [".pdf", ".docx", ".doc"];
                              const validFormat = acceptedFormats.some(
                                (format) => value[0].name.endsWith(format)
                              );
                              if (!validFormat)
                                return "*Debes cargar un documento en formato PDF, .docx o .doc";
                            },
                            maxFileSize: (value) => {
                              if (!value[0]) return "*Debe cargar un archivo";
                              const isMaxFileSizeAllow =
                                value[0].size <= MAX_SIZE_FILE;
                              if (!isMaxFileSizeAllow)
                                return `*El archivo debe tener un tamaño máximo de ${
                                  MAX_SIZE_FILE / 1024 / 1024
                                }MB`;
                            },
                          },
                        })}
                        accept=".pdf, .docx, .doc"
                        type="file"
                      />
                      <Form.Label
                        className={"input-upload__button_primary_color"}
                        htmlFor={`otherDocuments.${index}`}
                      >
                        <CloudUploadOutlinedIcon fontSize="large" />
                        {file ? ` ${file.name}` : " adjuntar documento"}
                      </Form.Label>
                      <Form.Label
                        className="input-upload__button"
                        onClick={() => handleDeleteOtherDocument(index)}
                        style={{ marginLeft: "1rem" }}
                      >
                        <DeleteIcon fontSize="large" />
                      </Form.Label>
                    </Col>
                    <span className="caption custom-input__error">
                      {errors?.otherDocuments?.length > 0 &&
                        errors?.otherDocuments[index] &&
                        errors?.otherDocuments[index]?.message}
                    </span>
                  </Row>
                  <br />
                </div>
              );
            })}
            <br />
            {fields.length < 10 && (
              <Row xs={"auto"}>
                {disableUpload ? (
                  <CustomToolTip
                    direction="top"
                    message="La compañía ha alcanzado el máximo de su plan de almacenamiento, contáctate con el usuario administrador"
                  >
                    <Button
                      type="button"
                      disabled="true"
                      variant="contained"
                      startIcon={<AddIcon fontSize="large" />}
                      className="btn_disable"
                      onClick={handleUploadOtherDocument}
                    >
                      Cargar Documentos
                    </Button>
                  </CustomToolTip>
                ) : (
                  <Button
                    type="button"
                    variant="contained"
                    startIcon={<AddIcon fontSize="large" />}
                    className="custom-input__button__secondary-color"
                    onClick={handleUploadOtherDocument}
                  >
                    Cargar Documentos
                  </Button>
                )}
              </Row>
            )}
            <br />
            {/* Botones de validaciones formularios */}
            <Row style={{ justifyContent: "end" }}>
              <Col xs={"auto"}>
                <Button
                  type="button"
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  disabled={isLoading}
                  onClick={() => navigate(-1)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  type="submit"
                  variant="contained"
                  className="custom-input__button__primary-color"
                  disabled={isLoading}
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Row>
      <ModalInfo
        onClose={() => setIsOpenModalInfoResponse(false)}
        open={isOpenModalInfoResponse}
        responseData={responseData}
        title={
          responseData?.status !== CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
            ? "Atención"
            : "Sin permisos suficientes"
        }
      />
      <ModalSelectPartForRequest
        openSelectPart={openSelectPart.open}
        setOpenSelectPart={(value) =>
          setOpenSelectPart({ open: value, idxSection: null })
        }
        selectPart={selectPart}
      />
    </Container>
  );
};

const FormFieldList = ({
  register,
  unregister,
  setValue,
  watch,
  setError,
  errors,
  field,
  index,
  idxSection,
}) => (
  <>
    <Col md={4}>
      <Form.Label
        className="form__label"
        {...register(`section.${idxSection}.answers.${index}.question`, {
          value: field.value,
        })}
      >
        {field.value}
        {field?.validations?.required?.value && "*"}
      </Form.Label>
    </Col>
    <Col md={5}>
      <Form.Select
        as="select"
        defaultValue={""}
        bsPrefix={
          errors?.section?.[idxSection]?.answers?.[index]?.answer?.message
            ? "input-group-container__error form__input"
            : "form__input"
        }
        {...register(`section.${idxSection}.answers.${index}.answer`, {
          required: {
            value: field?.validations?.required?.value,
            message: field?.validations?.required?.message,
          },
        })}
        onChange={(event) => {
          setValue(
            `section.${idxSection}.answers.${index}.answer`,
            event.target.value
          );
          setError(`section.${idxSection}.answers.${index}.answer`, "");
          if (event.target.value !== field.subtype?.answerToShow) {
            unregister(`section.${idxSection}.answers.${index}.subType`);
          }
        }}
      >
        <option className="label" value="" disabled>
          Selecciona
        </option>
        {field.options.map((value) => (
          <option key={value} className="label" value={value}>
            {value}
          </option>
        ))}
      </Form.Select>
      <span className="caption custom-input__error">
        {errors?.section?.[idxSection]?.answers?.[index]?.answer?.message}
      </span>
    </Col>
    <Col md={3}>
      {field?.subtypes?.length > 0 &&
        field?.subtypes.map((subfield) => {
          return (
            <div key={uuid()}>
              {watch(`section.${idxSection}.answers.${index}.answer`) ===
                subfield?.answerToShow && (
                <Form.Control
                  as="textarea"
                  {...register(
                    `section.${idxSection}.answers.${index}.subType`,
                    {
                      required: {
                        value: subfield?.validations?.required?.value,
                        message: subfield?.validations?.required?.message,
                      },
                      minLength: subfield?.validations?.minLength && {
                        value: subfield?.validations?.minLength?.value,
                        message: subfield?.validations?.minLength?.message,
                      },
                      maxLength: subfield?.validations?.maxLength && {
                        value: subfield?.validations?.maxLength?.value,
                        message: subfield?.validations?.maxLength?.message,
                      },
                      pattern: subfield?.validations?.pattern && {
                        value: REGEXP[subfield?.validations?.pattern?.value],
                        message: subfield?.validations?.pattern?.message,
                      },
                    }
                  )}
                  placeholder={
                    subfield?.placeholder || "Ingrese su respuesta aquí"
                  }
                  bsPrefix={
                    errors?.section?.[idxSection]?.answers?.[index]?.subType
                      ?.message
                      ? "input-group-container__error form__input"
                      : "form__input"
                  }
                  maxLength={subfield?.validations?.maxLength?.value || "1000"}
                />
              )}

              {watch(`section.${idxSection}.answers.${index}.answer`) ===
                "Otro(s)" &&
                subfield?.validations?.maxLength?.value && (
                  <span className="caption__primary-color">{`Máximo ${subfield?.validations?.maxLength?.value} caracteres`}</span>
                )}
              {watch(`section.${idxSection}.answers.${index}.answer`) ===
                "Otro(Explique)" &&
                subfield?.validations?.maxLength?.value && (
                  <span className="caption__primary-color">{`Máximo ${subfield?.validations?.maxLength?.value} caracteres`}</span>
                )}

              <span className="caption custom-input__error">
                {
                  errors?.section?.[idxSection]?.answers[index]?.subType
                    ?.message
                }
              </span>
            </div>
          );
        })}
    </Col>
  </>
);

const FormFieldInput = ({ register, errors, field, index, idxSection }) => (
  <>
    <>
      <Col md={4}>
        <Form.Label
          className="form__label"
          {...register(`section.${idxSection}.answers.${index}.question`, {
            value: field.value,
          })}
        >
          {field.value}
          {field?.validations?.required?.value && "*"}
        </Form.Label>
        <br></br>
        {field?.validations?.maxLength?.value && (
          <span className="caption__primary-color">
            {" "}
            {`Máximo ${field?.validations?.maxLength?.value} caracteres`}
          </span>
        )}
      </Col>
      <Col md={4}>
        <Form.Control
          as="textarea"
          bsPrefix={
            errors?.section?.[idxSection]?.answers?.[index]?.answer?.message
              ? "input-group-container__error form__input"
              : "form__input"
          }
          placeholder={
            field.placeholder
              ? field.placeholder
              : "Ingrese su respuesta aquí..."
          }
          className="form__input"
          {...register(`section.${idxSection}.answers.${index}.answer`, {
            required: {
              value: field?.validations?.required?.value,
              message: field?.validations?.required?.message,
            },
            minLength: field?.validations?.minLength && {
              value: field?.validations?.minLength?.value,
              message: field?.validations?.minLength?.message,
            },
            maxLength: field?.validations?.maxLength && {
              value: field?.validations?.maxLength?.value,
              message: field?.validations?.maxLength?.message,
            },
            pattern: field?.validations?.pattern && {
              value: REGEXP[field?.validations?.pattern?.value],
              message: field?.validations?.pattern?.message,
            },
          })}
          maxLength={
            field?.validations?.maxLength
              ? field?.validations?.maxLength?.value
              : "1000"
          }
        />
        <span className="caption custom-input__error">
          {errors?.section?.[idxSection]?.answers?.[index]?.answer?.message}
        </span>
      </Col>
    </>
  </>
);

const FormFieldListInput = ({ register, errors, field, index, idxSection }) => (
  <>
    <Col md={4}>
      <Form.Label
        className="form__label"
        {...register(`section.${idxSection}.answers.${index}.question`, {
          value: field.value,
        })}
      >
        {field.value}
        {field?.validations?.required?.value && "*"}
      </Form.Label>
    </Col>
    <Col xs={"auto"}>
      <Form.Select
        as="select"
        defaultValue={""}
        bsPrefix={
          errors?.section?.[idxSection]?.answers?.[index]?.answer1?.message
            ? "input-group-container__error form__input"
            : "form__input"
        }
        {...register(`section.${idxSection}.answers.${index}.answer1`, {
          required: {
            value: field?.validations?.required?.value,
            message: field?.validations?.required?.message,
          },
        })}
      >
        <option className="label" value="" disabled>
          Selecciona
        </option>
        {field.options.map((value) => (
          <option key={value} className="label" value={value}>
            {value}
          </option>
        ))}
      </Form.Select>
      <span className="caption custom-input__error">
        {errors?.section?.[idxSection]?.answers?.[index]?.answer1?.message}
      </span>
    </Col>
    <Col md={4}>
      <Form.Control
        type="text"
        bsPrefix={
          errors?.section?.[idxSection]?.answers?.[index]?.answer2?.message
            ? "input-group-container__error form__input"
            : "form__input"
        }
        placeholder={
          field.placeholder ? field.placeholder : "Ingrese su respuesta aquí..."
        }
        className="form__input"
        {...register(`section.${idxSection}.answers.${index}.answer2`, {
          required: {
            value: field?.validations?.required?.value,
            message: field?.validations?.required?.message,
          },
          minLength: field?.validations?.minLength && {
            value: field?.validations?.minLength?.value,
            message: field?.validations?.minLength?.message,
          },
          maxLength: field?.validations?.maxLength && {
            value: field?.validations?.maxLength?.value,
            message: field?.validations?.maxLength?.message,
          },
          pattern: field?.validations?.pattern && {
            value: REGEXP[field?.validations?.pattern?.value],
            message: field?.validations?.pattern?.message,
          },
        })}
        maxLength={
          field?.validations?.maxLength
            ? field?.validations?.maxLength?.value
            : "1000"
        }
      />
      <span className="caption custom-input__error">
        {errors?.section?.[idxSection]?.answers?.[index]?.answer2?.message}
      </span>
    </Col>
  </>
);

const FormFieldListCheckbox = ({
  register,
  setError,
  setValue,
  watch,
  errors,
  field,
  index,
  idxSection,
}) => (
  <>
    <Col md={4}>
      <Form.Label
        className="form__label"
        {...register(`section.${idxSection}.answers.${index}.question`, {
          value: field.value,
        })}
      >
        {field.value}
        {field?.validations?.required?.value && "*"}
      </Form.Label>
    </Col>
    <Col xs={"auto"}>
      <Form.Select
        disabled={watch(`section.${idxSection}.answers.${index}.check`)}
        as="select"
        defaultValue={""}
        bsPrefix={
          errors?.section?.[idxSection]?.answers?.[index]?.answer?.message
            ? "input-group-container__error form__input"
            : "form__input"
        }
        {...register(`section.${idxSection}.answers.${index}.answer`, {
          required: {
            value:
              field?.validations?.required?.value &&
              !watch(`section.${idxSection}.answers.${index}.check`),
            message: field?.validations?.required?.message,
          },
        })}
      >
        <option className="label" value="" disabled>
          Selecciona
        </option>
        {field.options.map((value) => (
          <option key={value} className="label" value={value}>
            {value}
          </option>
        ))}
      </Form.Select>
      <span className="caption custom-input__error">
        {errors?.section?.[idxSection]?.answers?.[index]?.answer?.message}
      </span>
    </Col>
    <Col xs={"auto"}>
      <Form.Check
        {...register(`section.${idxSection}.answers.${index}.check`)}
        type={"checkbox"}
        label={field?.label}
        className="form__label"
        onClick={(event) => {
          if (event.target.checked) {
            setValue(
              `section.${idxSection}.answers.${index}.answer`,
              field?.label
            );
            setError(`section.${idxSection}.answers.${index}.answer`, "");
          } else {
            setValue(`section.${idxSection}.answers.${index}.answer`, "");
          }
        }}
      />
    </Col>
  </>
);

const FormFieldInputCheckbox = ({
  register,
  setError,
  setValue,
  watch,
  errors,
  field,
  index,
  idxSection,
}) => (
  <>
    <Col md={4}>
      <Form.Label
        className="form__label"
        {...register(`section.${idxSection}.answers.${index}.question`, {
          value: field.value,
        })}
      >
        {field.value}
        {field?.validations?.required?.value && "*"}
      </Form.Label>
      <br></br>
      {field?.validations?.maxLength?.value && (
        <span className="caption__primary-color">{`Máximo ${field?.validations?.maxLength?.value} caracteres`}</span>
      )}
    </Col>
    <Col md={5}>
      <Form.Control
        disabled={watch(`section.${idxSection}.answers.${index}.check`)}
        as="textarea"
        bsPrefix={
          errors?.section?.[idxSection]?.answers?.[index]?.answer?.message
            ? "input-group-container__error form__input"
            : "form__input"
        }
        placeholder={
          field.placeholder ? field.placeholder : "Ingrese su respuesta aquí..."
        }
        className="form__input"
        {...register(`section.${idxSection}.answers.${index}.answer`, {
          required: {
            value:
              field?.validations?.required?.value &&
              !watch(`section.${idxSection}.answers.${index}.check`),
            message: field?.validations?.required?.message,
          },
          minLength: !watch(`section.${idxSection}.answers.${index}.check`) &&
            field?.validations?.minLength && {
              value: field?.validations?.minLength?.value,
              message: field?.validations?.minLength?.message,
            },
          maxLength: !watch(`section.${idxSection}.answers.${index}.check`) &&
            field?.validations?.maxLength && {
              value: field?.validations?.maxLength?.value,
              message: field?.validations?.maxLength?.message,
            },
          pattern: !watch(`section.${idxSection}.answers.${index}.check`) &&
            field?.alidations?.pattern && {
              value: REGEXP[field?.validations?.pattern?.value],
              message: field?.validations?.pattern?.message,
            },
        })}
        maxLength={
          field?.validations?.maxLength
            ? field?.validations?.maxLength?.value
            : "1000"
        }
      />
      <span className="caption custom-input__error">
        {errors?.section?.[idxSection]?.answers?.[index]?.answer?.message}
      </span>
    </Col>
    <Col xs={"auto"}>
      <Form.Check
        {...register(`section.${idxSection}.answers.${index}.check`)}
        type={"checkbox"}
        label={field?.label}
        className="form__label"
        onClick={(event) => {
          if (event.target.checked) {
            setValue(
              `section.${idxSection}.answers.${index}.answer`,
              field?.label
            );
            setError(`section.${idxSection}.answers.${index}.answer`, "");
          } else {
            setValue(`section.${idxSection}.answers.${index}.answer`, "");
          }
        }}
      />
    </Col>
  </>
);

const FormFieldListInputCheckbox = ({
  register,
  setValue,
  watch,
  setError,
  errors,
  field,
  index,
  idxSection,
}) => (
  <>
    <Col md={4}>
      <Form.Label
        className="form__label"
        {...register(`section.${idxSection}.answers.${index}.question`, {
          value: field.value,
        })}
      >
        {field.value}
        {field?.validations?.required?.value && "*"}
      </Form.Label>
      <br></br>
      {field?.validations?.maxLength?.value && (
        <span className="caption__primary-color">{`Máximo ${field?.validations?.maxLength?.value} caracteres`}</span>
      )}
    </Col>
    <Col xs={"auto"}>
      <Form.Select
        disabled={watch(`section.${idxSection}.answers.${index}.check`)}
        as="select"
        defaultValue={""}
        bsPrefix={
          errors?.section?.[idxSection]?.answers?.[index]?.answer1?.message
            ? "input-group-container__error form__input"
            : "form__input"
        }
        {...register(`section.${idxSection}.answers.${index}.answer1`, {
          required: {
            value:
              field?.validations?.required?.value &&
              !watch(`section.${idxSection}.answers.${index}.check`),
            message: field?.validations?.required?.message,
          },
        })}
      >
        <option className="label" value="" disabled>
          Selecciona
        </option>
        {field.options.map((value) => (
          <option key={value} className="label" value={value}>
            {value}
          </option>
        ))}
      </Form.Select>
      <span className="caption custom-input__error">
        {errors?.section?.[idxSection]?.answers?.[index]?.answer1?.message}
      </span>
    </Col>
    <Col md={4}>
      <Form.Control
        disabled={watch(`section.${idxSection}.answers.${index}.check`)}
        as="textarea"
        bsPrefix={
          errors?.section?.[idxSection]?.answers?.[index]?.answer2?.message
            ? "input-group-container__error form__input"
            : "form__input"
        }
        placeholder={
          field.placeholder ? field.placeholder : "Ingrese su respuesta aquí..."
        }
        className="form__input"
        {...register(`section.${idxSection}.answers.${index}.answer2`, {
          required: {
            value:
              field?.validations?.required?.value &&
              !watch(`section.${idxSection}.answers.${index}.check`),
            message: field?.validations?.required?.message,
          },
          minLength: !watch(`section.${idxSection}.answers.${index}.check`) &&
            field?.validations?.minLength && {
              value: field?.validations?.minLength?.value,
              message: field?.validations?.minLength?.message,
            },
          maxLength: !watch(`section.${idxSection}.answers.${index}.check`) &&
            field?.validations?.maxLength && {
              value: field?.validations?.maxLength?.value,
              message: field?.validations?.maxLength?.message,
            },
          pattern: !watch(`section.${idxSection}.answers.${index}.check`) &&
            field?.validations?.pattern && {
              value: REGEXP[field?.validations?.pattern?.value],
              message: field?.validations?.pattern?.message,
            },
        })}
        maxLength={
          field?.validations?.maxLength
            ? field?.validations?.maxLength?.value
            : "1000"
        }
      />
      <span className="caption custom-input__error">
        {errors?.section?.[idxSection]?.answers?.[index]?.answer2?.message}
      </span>
    </Col>
    <Col xs={"auto"}>
      <Form.Check
        {...register(`section.${idxSection}.answers.${index}.check`)}
        type={"checkbox"}
        label={field?.label}
        className="form__label"
        onClick={(event) => {
          if (event.target.checked) {
            setValue(
              `section.${idxSection}.answers.${index}.answer`,
              field?.label
            );
            setValue(`section.${idxSection}.answers.${index}.answer1`, "");
            setValue(`section.${idxSection}.answers.${index}.answer2`, "");
            setError(`section.${idxSection}.answers.${index}.answer1`, "");
            setError(`section.${idxSection}.answers.${index}.answer2`, "");
          } else {
            setValue(`section.${idxSection}.answers.${index}.answer`, "");
            setValue(`section.${idxSection}.answers.${index}.answer1`, "");
            setValue(`section.${idxSection}.answers.${index}.answer2`, "");
          }
        }}
      />
    </Col>
  </>
);

const FormFieldDate = ({ register, errors, field, index, idxSection }) => (
  <>
    {" "}
    <Col md={4}>
      <Form.Label
        className="form__label"
        {...register(`section.${idxSection}.answers.${index}.question`, {
          value: field.value,
        })}
      >
        {field.value}
        {field?.validations?.required?.value && "*"}
      </Form.Label>
    </Col>
    <Col md={5}>
      <Form.Control
        type="date"
        className={
          errors?.section?.[idxSection]?.answers?.[index]?.answer?.message
            ? "input-group-container__error form__date"
            : "form__date"
        }
        {...register(`section.${idxSection}.answers.${index}.answer`, {
          required: {
            value: field?.validations?.required?.value,
            message: field?.validations?.required?.message,
          },
        })}
        placeholder="AAAA-MM-DD"
      />
      <span className="caption custom-input__error">
        {errors?.section?.[idxSection]?.answers?.[index]?.answer?.message}
      </span>
    </Col>
  </>
);

const FormFieldDateCheckbox = ({
  register,
  setValue,
  watch,
  setError,
  errors,
  field,
  index,
  idxSection,
}) => (
  <>
    <Col md={4}>
      <Form.Label
        className="form__label"
        {...register(`section.${idxSection}.answers.${index}.question`, {
          value: field.value,
        })}
      >
        {field.value}
        {field?.validations?.required?.value && "*"}
      </Form.Label>
    </Col>
    <Col md={5}>
      <Form.Control
        disabled={watch(`section.${idxSection}.answers.${index}.check`)}
        type="date"
        className={
          errors?.section?.[idxSection]?.answers?.[index]?.answer?.message
            ? "input-group-container__error form__date"
            : "form__date"
        }
        {...register(`section.${idxSection}.answers.${index}.answer`, {
          required: {
            value: field?.validations?.required?.value,
            message: field?.validations?.required?.message,
          },
        })}
        placeholder="AAAA-MM-DD"
      />
      <span className="caption custom-input__error">
        {errors?.section?.[idxSection]?.answers?.[index]?.answer?.message}
      </span>
    </Col>
    <Col xs={"auto"}>
      <Form.Check
        {...register(`section.${idxSection}.answers.${index}.check`)}
        type={"checkbox"}
        label={field?.label}
        className="form__label"
        onClick={(event) => {
          if (event.target.checked) {
            setValue(
              `section.${idxSection}.answers.${index}.answer`,
              field?.label
            );
            setError(`section.${idxSection}.answers.${index}.answer`, "");
          } else {
            setValue(`section.${idxSection}.answers.${index}.answer`, "");
          }
        }}
      />
    </Col>
  </>
);

export default CreateRequest;
