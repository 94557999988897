import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Container, Row, Col, Form } from "react-bootstrap";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  getAllBlankSpaces,
  getFillBlankSpaces,
} from "../../utils/countBlankspaces";
import { NUMBERINGWORDSO } from "../../utils/numberings";

const FillBlankspacesBox = ({
  title,
  clause,
  indexClause,
  customBox,
  handleBlankspacesClause,
  handleBlankspacesSubclause,
  handleBlankspacesSubclauseChild,
  handleBlankspacesParagraphs,
  isCompleteCustomBox = false,
}) => {
  //state
  const [isCompleteClause, setIsCompleteClause] = useState(false);
  //useEffect
  useEffect(() => {
    if (clause) {
      const fillBlankSpaces = getFillBlankSpaces({ clause });
      const allBlankSpaces = getAllBlankSpaces({ clause });

      if (fillBlankSpaces > 0) {
        if (fillBlankSpaces / allBlankSpaces === 1 || allBlankSpaces === 0) {
          setIsCompleteClause(true);
        } else {
          setIsCompleteClause(false);
        }
      } else {
        setIsCompleteClause(false);
      }
    }
  }, [clause]);

  const flashItemSelected = (idItem) => {
    let item = document.getElementById(idItem);
    const docView = document.getElementById("content-document");
    const keyframes = [
      { backgroundColor: "#FFC4C0" },
      { backgroundColor: "#FFFFFF" },
    ];
    const options = { duration: 4000, iterations: 1 };
    const elementRect = item.getBoundingClientRect();
    const position =
      docView.scrollTop +
      (elementRect.top - docView.getBoundingClientRect().height / 2);
    docView.scrollTo({
      top: position,
      behavior: "smooth",
    });
    if (item) item.animate(keyframes, options);
  };

  return (
    <Accordion className="container-blankspaces">
      <AccordionSummary className="container-blankspaces">
        <Container
          fluid
          className="container-blankspaces__box"
          style={{ margin: "0 !important" }}
        >
          <Row>
            <Col xs={10} className="heading">
              {title}
            </Col>
            <Col xs={1}>
              {isCompleteClause || isCompleteCustomBox ? (
                <CheckCircleOutlineIcon fontSize="large" />
              ) : (
                <InfoOutlinedIcon
                  className="heading__error-color"
                  fontSize="large"
                />
              )}
            </Col>
            <Col xs={1}>
              <KeyboardArrowDownIcon fontSize="large" />
            </Col>
          </Row>
        </Container>
      </AccordionSummary>
      <AccordionDetails className="container-blankspaces__box">
        {customBox || (
          <>
            {clause?.blankspaces?.map((blankspace, index) => (
              <Form.Group className="mb-3" key={blankspace.name}>
                <Form.Control
                  as={"textarea"}
                  placeholder={`Espacio ${index + 1}`}
                  bsPrefix={"input-group-container__no-icon label"}
                  onChange={(e) => {
                    handleBlankspacesClause({
                      text: clause.text,
                      name: blankspace.name,
                      value: e.target.value,
                      id: clause._id,
                    });
                  }}
                  onFocus={() => {
                    const id = `${indexClause + 1}.0.0`;
                    flashItemSelected(id);
                  }}
                  value={blankspace?.value || ""}
                />
              </Form.Group>
            ))}
            {clause?.subclauses?.length > 0 &&
              clause?.subclauses?.map((subclause, index) => (
                <div key={`${subclause?.subclause?._id}`}>
                  {subclause?.subclause?.blankspaces?.length > 0 && (
                    <>
                      <Row className="heading">
                        <Col>{`Numeral ${index + 1}`}</Col>
                      </Row>
                      <Row>
                        <Col>
                          {subclause?.subclause?.blankspaces.map(
                            (blankspace, index2) => (
                              <Form.Group
                                className="mb-3"
                                key={blankspace.name}
                              >
                                <Form.Control
                                  as={"textarea"}
                                  placeholder={`Espacio ${index2 + 1}`}
                                  bsPrefix={
                                    "input-group-container__no-icon label"
                                  }
                                  onFocus={() => {
                                    const id = `${indexClause}.${index + 1}.0`;
                                    flashItemSelected(id);
                                  }}
                                  onChange={(e) => {
                                    handleBlankspacesSubclause({
                                      text: subclause.subclause.text,
                                      name: blankspace.name,
                                      value: e.target.value,
                                      index,
                                      clauseId: clause._id,
                                    });
                                  }}
                                  value={blankspace?.value || ""}
                                />
                              </Form.Group>
                            )
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  {subclause?.subclause?.childs.length > 0 &&
                    subclause?.subclause?.childs?.map((child, index2) => (
                      <div key={`${child?._id}`}>
                        {child?.blankspaces?.length > 0 && (
                          <>
                            <Row className="heading">
                              <Col
                                md={3}
                              >{`Diligenciar ${child.blankspaces.length} espacios:`}</Col>

                              <Col md={3}>{`${index + 1}.${index2 + 1}. ${
                                child.title ? child.title : "Sin titulo"
                              }`}</Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                {child.blankspaces.map((blankspace, index3) => (
                                  <Form.Group
                                    className="mb-3"
                                    key={`${blankspace.name}`}
                                  >
                                    <Form.Control
                                      as={"textarea"}
                                      placeholder={`Espacio ${index3 + 1}`}
                                      bsPrefix={
                                        "input-group-container__no-icon label"
                                      }
                                      onChange={(e) => {
                                        handleBlankspacesSubclauseChild(
                                          child.text,
                                          blankspace.name,
                                          e.target.value,
                                          index,
                                          index2,
                                          clause._id
                                        );
                                      }}
                                      onFocus={() => {
                                        const id = `${indexClause}.${
                                          index + 1
                                        }.${index2 + 1}`;
                                        flashItemSelected(id);
                                      }}
                                      value={blankspace?.value || ""}
                                    />
                                  </Form.Group>
                                ))}
                              </Col>
                              <Col md={9}>
                                <div className="caption container-blankspaces">
                                  <p style={{ padding: "0.5%" }}>
                                    {ReactHtmlParser(child.text)}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    ))}
                </div>
              ))}
            {clause?.paragraphs.length > 0 &&
              clause?.paragraphs?.map((paragraph, index) => (
                <div key={`${paragraph?.paragraph?._id}`}>
                  {paragraph?.paragraph?.blankspaces &&
                    paragraph?.paragraph?.blankspaces.length > 0 && (
                      <>
                        <Row className="heading">
                          <Col
                            md={3}
                          >{`Diligenciar ${paragraph?.paragraph?.blankspaces?.length} espacios:`}</Col>

                          <Col md={3}>{`Parágrafo ${
                            NUMBERINGWORDSO[index + 1]
                          }`}</Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            {paragraph?.paragraph?.blankspaces.map(
                              (blankspace, index4) => (
                                <Form.Group
                                  className="mb-3"
                                  key={`${blankspace.name}`}
                                >
                                  <Form.Control
                                    placeholder={`Espacio ${index4 + 1}`}
                                    bsPrefix={
                                      "input-group-container__no-icon label"
                                    }
                                    as={"textarea"}
                                    onChange={(e) => {
                                      handleBlankspacesParagraphs(
                                        paragraph.paragraph.text,
                                        blankspace.name,
                                        e.target.value,
                                        index,
                                        clause._id
                                      );
                                    }}
                                    onFocus={() => {
                                      const id = `${indexClause}.0.${
                                        index + 1
                                      }`;
                                      flashItemSelected(id);
                                    }}
                                    value={blankspace?.value || ""}
                                  />
                                </Form.Group>
                              )
                            )}
                          </Col>
                          <Col md={9}>
                            <div className="caption container-blankspaces">
                              <p style={{ padding: "0.5%" }}>
                                {ReactHtmlParser(paragraph.paragraph.text)}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                </div>
              ))}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FillBlankspacesBox;
