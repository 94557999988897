import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Button, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { MDBDataTableV5 } from "mdbreact";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import EllipsisComponent from "../../components/Text/ElipsisComponent";
import PopoverActions from "../../components/popover/PopoverActions";
import { CODES } from "../../utils/codes";
import LoadingContent from "../../components/LoadingContent";
import Breadcrum from "../../pages/parts/breadcrum";
import SectionTitle from "../../components/sectionTitle";
import CustomSearchFilterButton from "../../components/Search/customSearchFilterButton";
import { onSortHTMLElement } from "../../utils/findComponentHTML";
import ModalInfo from "../../components/Modals/ModalInfo";
import ModalSelectRoleSignatory from "../../components/Modals/ModalSelectRoleSignatory";
import { PARTS_LIBRARY_STATE } from "../../utils/partState";
import {
  findPartById,
  findSignatoriesByCompany,
} from "../../services/parts/partsServices";
import BlockerPage from "../../components/blocker/blocker";
import { handleCopyClick } from "../../utils/copyText";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import dateFormat from "dateformat";
import botImage from "../../assets/svg/bot.png";
import ReactHtmlParser from "react-html-parser";
import { removeHtmlTags } from "../../utils/removeHTMLTags";
export const SelectSignatoryForContract = ({
  openSelectSignatory,
  setOpenSelectSignatory,
  type,
  handleFillBlankspacesOtherSignatories,
  otherSignatoryInfo,
  signatoryInfo,
  handleFillBlankspacesSignatories,
  currentDocument,
}) => {
  //States

  const [signatoriesList, setSignatoriesList] = useState([]);

  const [part, setPart] = useState({});

  const [openModalNoSignatories, setOpenModalNoSignatories] = useState(false);

  const columns = [
    {
      label: [<label aria-hidden="true" key="0"></label>],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre del firmante.
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Identificación del firmante.
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Parte relacionada.
        </label>,
      ],
      field: "partName",
    },
    {
      label: [
        <label aria-hidden="true" key="5">
          Cargo del firmante en la Parte
        </label>,
      ],
      field: "partPosition",
    },
  ];

  const columnsNoParts = [
    {
      label: [<label aria-hidden="true" key="0"></label>],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre del firmante.
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Identificación del firmante.
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
  ];
  const [data, setData] = useState({ columns, rows: [] });
  const [filteredData, setFilteredData] = useState({ columns, rows: [] });

  const [selectedSignatories, setSelectedSignatories] = useState([]);

  const [isLoading, setIsLoading] = useState(null);

  const [openModalSelectSignatoryRole, setOpenModalSelectSignatoryRole] =
    useState(false);

  //Handles

  const getName = (item) => {
    let fullName = `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
    return fullName.replace(/  +/g, " ");
  };

  const handleCheckSignatory = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedSignatories([item]);
    } else {
      setSelectedSignatories(
        selectedSignatories.filter((itm) => itm?._id !== item?._id)
      );
    }
  };

  const buildData = (data) => {
    if (!data?.length) return [];
    const formatedData = [];
    for (const item of data) {
      const signatory = item.signatory;
      let name = getName(signatory);
      let id = `${signatory?.documentType}. ${signatory?.documentNumber}`;
      const contact = `${signatory?.email || ""}`;
      const address = signatory?.residence?.physicalAddress;
      const country = signatory?.residence?.country;
      const city = `${signatory?.residence?.province?.concat(", ") || ""} ${
        signatory?.residence?.city || ""
      }`;
      const number = `+${signatory?.mobileNumberData?.countryCode || ""} ${
        signatory?.mobileNumberData?.phone || ""
      }`;

      let telephone = `+${signatory?.countryCodeTelephone || ""} ${
        signatory?.cityCode || ""
      } ${signatory?.numberTelephone || ""}`;

      formatedData.push({
        name,

        partName: (
          <Row className="align-items-center">
            <Col xs={12}>
              <span className="caption__primary-color__active ">
                {part?.blankspaces[0]?.value}
              </span>
            </Col>
          </Row>
        ),
        partPosition: (
          <Row className="align-items-center">
            <Col xs={"auto"} lg={8}>
              <span className="caption__primary-color__active ">
                {item.position}
              </span>
            </Col>

            {item?.limitations && (
              <Col xs={"auto"} lg={4}>
                <PopoverActions
                  parentId="clause-parent"
                  type="xlarge"
                  classNameButton=""
                  description={
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col xs={12} md={4}>
                          <img
                            alt="Preview Bot image"
                            className="bot__image"
                            src={botImage}
                          />
                        </Col>

                        <Col xs={12} md={8}>
                          <Row>
                            <Col xs={12}>
                              <span className="home-init-cards-row__title-row">
                                Limitaciones a la representación legal:{" "}
                              </span>
                            </Col>

                            {item?.signatory?.createdAt && (
                              <Col xs={12} className="text-start mb-3">
                                <span className="caption">
                                  {`Creado el ${dateFormat(
                                    item?.signatory?.createdAt,
                                    "d 'de' mmmm 'del 'yyyy"
                                  )}`}{" "}
                                </span>
                              </Col>
                            )}

                            {item?.limitationsUpdatedDate && (
                              <Col xs={12} className="text-start mb-3">
                                <span className="caption">
                                  {`Modificado el ${dateFormat(
                                    item?.limitationsUpdatedDate,
                                    "d 'de' mmmm 'del 'yyyy"
                                  )}`}{" "}
                                </span>
                              </Col>
                            )}

                            <Col xs={12} className="container__max-height">
                              <span className="caption">
                                {ReactHtmlParser(item?.limitations)}
                              </span>
                            </Col>

                            <Col xs={12} className="mt-5 text-start">
                              <span
                                className="heading__hover cursor__pointer"
                                onClick={() =>
                                  handleCopyClick(
                                    removeHtmlTags(item?.limitations)
                                  )
                                }
                              >
                                Copiar texto{" "}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  }
                  icon={
                    <ReportOutlinedIcon
                      fontSize="large"
                      style={{ fill: "#F9600B" }}
                    />
                  }
                />
              </Col>
            )}
          </Row>
        ),
        select: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <Checkbox
                checked={selectedSignatories?.some(
                  (itm) => itm?._id === item?._id
                )}
                onClick={(e) => handleCheckSignatory(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),

        ...item,
      });
    }

    return formatedData.sort((a, b) => a.name.localeCompare(b.name));
  };

  const buildDataNoPart = (data) => {
    if (!data?.length) return [];
    const formatedData = [];

    for (const item of data) {
      let name = getName(item);
      let id = `${item?.documentType}. ${item?.documentNumber}`;
      const contact = `${item?.email || ""}`;
      const address = item?.residence?.physicalAddress;
      const country = item?.residence?.country;
      const city = `${item?.residence?.province?.concat(", ") || ""} ${
        item?.residence?.city || ""
      }`;
      const number = `+${item?.mobileNumberData?.countryCode || ""} ${
        item?.mobileNumberData?.phone || ""
      }`;

      let telephone = `+${item?.countryCodeTelephone || ""} ${
        item?.cityCode || ""
      } ${item?.numberTelephone || ""}`;

      formatedData.push({
        name,

        select: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <Checkbox
                checked={selectedSignatories?.some(
                  (itm) => itm?._id === item?._id
                )}
                onClick={(e) => handleCheckSignatory(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),

        ...item,
      });
    }

    return formatedData.sort((a, b) => a.name.localeCompare(b.name));
  };

  const buildSignatoryData = (data) => {
    const arrayOfProperties = [];

    let role = {
      newText: data?.role,
      indexBlank: 0,
      indexSignatory: otherSignatoryInfo.signatoryIndex,
      nameBlank: "blankSpace-1",
      signatoryId: data?._id,
    };

    let name = {
      newText: getName(data),
      indexBlank: 1,
      indexSignatory: otherSignatoryInfo.signatoryIndex,
      nameBlank: "blankSpace-2",
    };

    let documentType = {
      newText: data?.documentType,
      indexBlank: 2,
      indexSignatory: otherSignatoryInfo.signatoryIndex,
      nameBlank: "blankSpace-3",
    };

    let documentNumber = {
      newText: data?.documentNumber,
      indexBlank: 3,
      indexSignatory: otherSignatoryInfo.signatoryIndex,
      nameBlank: "blankSpace-4",
    };

    arrayOfProperties.push(role, name, documentType, documentNumber);

    return arrayOfProperties;
  };

  const buildSignatoryDataWithPart = (data) => {
    const arrayOfProperties = [];

    let role = {
      newText: data?.position,
      indexBlank: 0,
      indexSignatory: signatoryInfo.signatoryIndex,
      indexPart: signatoryInfo.indexPart,
      indexRole: signatoryInfo.indexRole,
      nameBlank: "blankSpace-1",
      signatoryId: data?.signatory?._id,
    };

    let name = {
      newText: getName(data.signatory),
      indexBlank: 1,
      indexSignatory: signatoryInfo.signatoryIndex,
      indexPart: signatoryInfo.indexPart,
      indexRole: signatoryInfo.indexRole,
      nameBlank: "blankSpace-2",
    };

    let documentType = {
      newText: data?.signatory?.documentType,
      indexBlank: 2,
      indexSignatory: signatoryInfo.signatoryIndex,
      indexPart: signatoryInfo.indexPart,
      indexRole: signatoryInfo.indexRole,
      nameBlank: "blankSpace-3",
    };

    let documentNumber = {
      newText: data?.signatory?.documentNumber,
      indexBlank: 3,
      indexSignatory: signatoryInfo.signatoryIndex,
      indexPart: signatoryInfo.indexPart,
      indexRole: signatoryInfo.indexRole,
      nameBlank: "blankSpace-4",
    };

    arrayOfProperties.push(role, name, documentType, documentNumber);

    return arrayOfProperties;
  };

  const handleCreateSignatory = () => {
    try {
      setIsLoading(true);
      if (type === "without-part") {
        selectedSignatories.forEach((signatory) => {
          const values = buildSignatoryData(signatory);

          values.forEach((element) => {
            handleFillBlankspacesOtherSignatories(element);
          });
        });
      } else {
        selectedSignatories.forEach((signatory) => {
          const values = buildSignatoryDataWithPart(signatory);
          values.forEach((element) => {
            handleFillBlankspacesSignatories(element);
          });
        });
      }

      setOpenSelectSignatory(false);
    } catch (error) {
      console.log(
        "============ Handle Create Signatory Error Start ============"
      );
      console.error(error);
      console.log(
        "============ Handle Create Signatory Error End ============"
      );
    } finally {
      setIsLoading(false);
    }
  };

  //Use effects

  useEffect(() => {
    const fetchData = async () => {
      try {
        const company = localStorage.getItem("company");

        const currentCorporateUnit = JSON.parse(
          localStorage.getItem("corporateUnit")
        );

        let associatedCorporateUnits = [currentCorporateUnit._id];

        const status = PARTS_LIBRARY_STATE.ACTIVE._id;
        setIsLoading(true);

        let response = {};
        if (type === "without-part") {
          response = await findSignatoriesByCompany({
            company,
            status,
            associatedCorporateUnits,
          });
        } else {
          response = await findPartById({
            partId: signatoryInfo?.part?._id,
          });
        }

        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          if (
            (type === "without-part" &&
              !response.data?.responseMessage?.data?.length) ||
            (type === "with-part" &&
              !response.data?.responseMessage?.data?.relatedSignatories?.length)
          ) {
            setOpenModalNoSignatories(true);
          }
          if (type === "without-part") {
            setSignatoriesList(response.data?.responseMessage?.data);
          } else {
            setPart(signatoryInfo.part);
            setSignatoriesList(
              response.data?.responseMessage?.data?.relatedSignatories
            );
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (currentDocument && signatoriesList) {
      if (type === "without-part") {
        const signatoryInfo =
          currentDocument?.otherSignatories[otherSignatoryInfo.signatoryIndex];
        let foundSignatory = signatoriesList.find(
          (item) => item?._id === signatoryInfo?._id
        );
        if (foundSignatory) {
          setSelectedSignatories((oldArray) => [...oldArray, foundSignatory]);
        }
      } else {
        const signatory =
          currentDocument?.parts[signatoryInfo?.indexRole]?.parts[
            signatoryInfo?.indexPart
          ]?.signatories[signatoryInfo?.signatoryIndex];

        let foundSignatory = signatoriesList.find(
          (item) => item?.signatory?._id === signatory?._id
        );
        if (foundSignatory) {
          setSelectedSignatories((oldArray) => [...oldArray, foundSignatory]);
        }
      }
    }
  }, [signatoriesList, currentDocument]);

  useEffect(() => {
    let tableColumns = [];
    let tranformData = [];
    if (type === "without-part") {
      tableColumns = columnsNoParts;
      tranformData = buildDataNoPart(signatoriesList);
    } else {
      tableColumns = columns;
      tranformData = buildData(signatoriesList);
    }

    setData({ rows: tranformData, columns: tableColumns });
    setFilteredData({ rows: tranformData, columns: tableColumns });
  }, [signatoriesList, selectedSignatories]);

  return (
    <Container fluid className="custom-container-scroll ">
      <BlockerPage
        blockNavigate={true}
        message={
          "Si continúas esta acción es posible que pierdas algunos datos."
        }
      />
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum
          state={openSelectSignatory}
          setState={setOpenSelectSignatory}
        />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={`Firmantes guardados`} />
      </Row>
      <Row className="mt-4">
        <Col xs={"auto"} className="caption">
          {`Escoge el o los firmantes que quieras:`}
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: 15 }}>
        <Col xs={7} xl={5}>
          <CustomSearchFilterButton
            placeholder="Buscar"
            list={data}
            setFilteredData={setFilteredData}
            validations={{ maxLength: 20 }}
          />
        </Col>
      </Row>

      <br />

      <br />
      <Row className="dt-list-center-content " style={{ marginBottom: "7rem" }}>
        {
          <MDBDataTableV5
            noRecordsFoundLabel={
              !data?.rows?.length
                ? "Aún no tienes partes creadas"
                : "No se encontraron resultados para tu búsqueda"
            }
            hover
            pagingTop
            searchBottom={false}
            entries={10}
            data={filteredData}
            infoLabel={["Mostrando", "a", "de", "firmantes"]}
            fullPagination
            onSort={(value) => {
              onSortHTMLElement({
                excludesColumns: ["actions", "corporateUnits"],
                sort: value,
                filteredDataTable: filteredData,
                setFilteredDataTable: setFilteredData,
              });
            }}
          />
        }
      </Row>
      <Row className="sidebar__bottom__container">
        <Col xs={2}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            startIcon={<CheckIcon fontSize="large" />}
            disabled={!selectedSignatories.length}
            onClick={() => {
              if (type === "without-part") {
                setOpenModalSelectSignatoryRole(true);
              } else {
                handleCreateSignatory();
              }
            }}
          >
            Seleccionar
          </Button>
        </Col>
      </Row>

      <ModalInfo
        title="Sin firmantes guardados"
        onClose={() => setOpenModalNoSignatories(false)}
        open={openModalNoSignatories}
        responseData={{
          data: {
            message: "No existen firmantes guardados en biblioteca",
          },
        }}
        confirmationText="Aceptar"
        buttonClose={true}
      />
      <ModalSelectRoleSignatory
        selectedSignatories={selectedSignatories}
        setSelectedSignatories={setSelectedSignatories}
        setIsOpen={setOpenModalSelectSignatoryRole}
        isOpen={openModalSelectSignatoryRole}
        title={"Terceros como Firmantes"}
        description={
          "Escribe la calidad que corresponda para entender por qué esta persona firmará el contrato si no está relacionada con ninguna de las partes"
        }
        submitForm={handleCreateSignatory}
      />
    </Container>
  );
};
