import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";

import { Button, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { MDBDataTableV5 } from "mdbreact";
import ClearIcon from "@mui/icons-material/Clear";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import CustomSearchFilterButton from "../../components/Search/customSearchFilterButton";
import FilterSignatory from "../../components/Filter/FilterSignatory";
import LoadingContent from "../../components/LoadingContent";
import SectionTitle from "../../components/sectionTitle";
import { CODES } from "../../utils/codes";
import ModalInfo from "../../components/Modals/ModalInfo";
import botImage from "../../assets/svg/bot.png";
import { PARTS_LIBRARY_STATE } from "../../utils/partState";
import { findPartByCompany } from "../../services/parts/partsServices";
import PopoverActions from "../../components/popover/PopoverActions";
import { onSortHTMLElement } from "../../utils/findComponentHTML";
import { handleCopyClick } from "../../utils/copyText";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import dateFormat from "dateformat";
import ReactHtmlParser from "react-html-parser";
import { removeHtmlTags } from "../../utils/removeHTMLTags";
export const ModalSelectPartForRequest = ({
  openSelectPart,
  setOpenSelectPart,
  selectPart,
}) => {
  //Ref
  const popoverActionRef = useRef(null);

  //State
  const columns = [
    {
      label: [<label aria-hidden="true" key="0"></label>],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre de la parte
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Identificación de la parte
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Nombre del firmante
        </label>,
      ],
      field: "signatoryName",
    },
    {
      label: [
        <label aria-hidden="true" key="5">
          Cargo del firmante
        </label>,
      ],
      field: "signatoryPosition",
    },
  ];

  const [signatoriesList, setSignatoriesList] = useState([]);
  const [signatoriesSelected, setSignatoriesSelected] = useState([]);
  const [openModalNoParts, setOpenModalNoParts] = useState(false);
  const [data, setData] = useState({ columns, rows: [] });
  const [filteredData, setFilteredData] = useState({ columns, rows: [] });
  const [partsList, setPartsList] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [isLoading, setIsLoading] = useState(null);

  //Router

  //Handles

  const getName = (item) => {
    let fullName = `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
    return fullName.replace(/  +/g, " ");
  };

  const handleCheckSignatory = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedParts([item]);
    } else {
      setSelectedParts(selectedParts.filter((itm) => itm?._id !== item?._id));
    }
  };

  const selectDefaultSignatory = (item) => {
    let defaultSignatory = {};
    item?.relatedSignatories.forEach((signatory) => {
      const isLegalPart =
        item?.typePart === "JURIDICA" &&
        signatory.position === "REPRESENTANTE LEGAL";
      const isDefaultSignatory = signatory?.isDefault;
      if (isLegalPart || isDefaultSignatory) {
        defaultSignatory = signatory;
      }
    });

    if (
      !Object.keys(defaultSignatory).length &&
      item?.typePart === "JURIDICA"
    ) {
      item?.relatedSignatories.forEach((signatory) => {
        if (signatory?.isDefault) {
          defaultSignatory = signatory;
        }
      });
    }
    return defaultSignatory;
  };

  const handleSignatoriesSelected = (signatories) => {
    setSignatoriesSelected(signatories);
  };

  const handleDeleteSignatorySelected = (signatory) => {
    const newSigantories = signatoriesSelected.filter(
      (t) => t._id !== signatory
    );
    setSignatoriesSelected(newSigantories);
    if (newSigantories.length > 0) {
      let filteredSignatories = [];
      const selectedList = newSigantories.map((item) => item._id);
      filteredData.rows.forEach((item) => {
        const list = item.relatedSignatories;
        const filterList = list.some((item) => selectedList.includes(item._id));
        if (filterList) {
          filteredSignatories.push(item);
        }
      });

      setFilteredData({ ...filteredData, rows: filteredSignatories });
    } else {
      setFilteredData(data);
    }
  };

  const buildData = (data) => {
    if (!data?.length) return [];
    const formatedData = [];
    let signatoriesDefaultList = [];
    for (const item of data) {
      let name = getName(item);
      let id = `${item?.documentType}. ${item?.documentNumber}`;
      const contact = `${item?.email || ""}`;
      const address = item?.residence?.physicalAddress;
      const country = item?.residence?.country;
      const city = `${item?.residence?.province?.concat(", ") || ""} ${
        item?.residence?.city || ""
      }`;
      const number = `+${item?.mobileNumberData?.countryCode || ""} ${
        item?.mobileNumberData?.phone || ""
      }`;

      let telephone = `+${item?.countryCodeTelephone || ""} ${
        item?.cityCode || ""
      } ${item?.numberTelephone || ""}`;

      if (item?.typePart === "JURIDICA") {
        name = item?.business?.businessName;
        id = `Nit. ${item?.business?.nit}`;
      }

      const defaultSignatory = selectDefaultSignatory(item);
      signatoriesDefaultList.push(defaultSignatory);
      const signatoryNameText = getName(defaultSignatory?.signatory);
      const signatoryPositionText = defaultSignatory.position;
      formatedData.push({
        name,
        defaultSignatory,
        signatoryName: (
          <Row className="align-items-center">
            <Col xs={12}>
              <span className="caption__primary-color__active ">
                {signatoryNameText}
              </span>
            </Col>
          </Row>
        ),
        signatoryPosition: (
          <Row className="align-items-center">
            <Col xs={"auto"} lg={8}>
              <span className="caption__primary-color__active ">
                {signatoryPositionText}
              </span>
            </Col>

            {defaultSignatory?.limitations && (
              <Col xs={"auto"} lg={4}>
                <PopoverActions
                  parentId="clause-parent"
                  type="xlarge"
                  classNameButton=""
                  containerRef={popoverActionRef}
                  description={
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col xs={12} md={4}>
                          <img
                            alt="Preview Bot image"
                            className="bot__image"
                            src={botImage}
                          />
                        </Col>

                        <Col xs={12} md={8}>
                          <Row>
                            <Col xs={12}>
                              <span className="home-init-cards-row__title-row">
                                Limitaciones a la representación legal:{" "}
                              </span>
                            </Col>
                            {defaultSignatory?.signatory?.createdAt && (
                              <Col xs={12} className="text-start mb-3">
                                <span className="caption">
                                  {`Creado el ${dateFormat(
                                    defaultSignatory?.signatory?.createdAt,
                                    "d 'de' mmmm 'del 'yyyy"
                                  )}`}{" "}
                                </span>
                              </Col>
                            )}
                            {defaultSignatory?.limitationsUpdatedDate && (
                              <Col xs={12} className="text-start mb-3">
                                <span className="caption">
                                  {`Modificado el ${dateFormat(
                                    defaultSignatory?.limitationsUpdatedDate,
                                    "d 'de' mmmm 'del 'yyyy"
                                  )}`}{" "}
                                </span>
                              </Col>
                            )}

                            <Col xs={12} className="container__max-height">
                              <span className="caption">
                                {ReactHtmlParser(
                                  removeHtmlTags(defaultSignatory?.limitations)
                                )}
                              </span>
                            </Col>

                            <Col xs={12} className="mt-5 text-start">
                              <span
                                className="heading__hover cursor__pointer"
                                onClick={() =>
                                  handleCopyClick(defaultSignatory?.limitations)
                                }
                              >
                                Copiar texto{" "}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  }
                  icon={
                    <ReportOutlinedIcon
                      fontSize="large"
                      style={{ fill: "#F9600B" }}
                    />
                  }
                />
              </Col>
            )}
          </Row>
        ),
        select: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <Checkbox
                checked={selectedParts?.some((itm) => itm?._id === item?._id)}
                onClick={(e) => handleCheckSignatory(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                  containerRef={popoverActionRef}
                />
              </Col>
            )}
          </Row>
        ),
        contactText: contact,
        signatoryNameText,
        signatoryPositionText,
        ...item,
      });
    }

    setSignatoriesList(
      signatoriesDefaultList
        .filter((item) => item.signatory)
        .map((item) => item.signatory)
    );
    return formatedData.sort((a, b) => a.name.localeCompare(b.name));
  };

  const handleAddPart = () => {
    if (!selectedParts.length) return null;
    selectPart(selectedParts[0]);
    setOpenSelectPart(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const company = localStorage.getItem("company");

        const currentCorporateUnit = JSON.parse(
          localStorage.getItem("corporateUnit")
        );

        let associatedCorporateUnits = [currentCorporateUnit._id];
        const status = PARTS_LIBRARY_STATE.ACTIVE._id;
        setIsLoading(true);
        const response = await findPartByCompany({
          company,
          status,
          associatedCorporateUnits,
        });
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          const list = response.data?.responseMessage?.data;
          setPartsList(list);

          if (!list?.length) {
            setOpenModalNoParts(true);
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (partsList?.length) {
      const tranformData = buildData(partsList);
      setData({ rows: tranformData, columns });
      setFilteredData({ rows: tranformData, columns });
    }
  }, [partsList, selectedParts]);

  useEffect(() => {
    setSelectedParts([]);
  }, [openSelectPart]);

  return (
    <Modal
      show={openSelectPart}
      fullscreen
      onHide={() => setOpenSelectPart(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="label_primary-color__page-title">Seleccionar parte</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={popoverActionRef}>
        <Container fluid className="custom-container-scroll ">
          {isLoading && <LoadingContent />}
          <Row xs={"auto"}>
            <SectionTitle title={`Partes guardadas`} />
          </Row>
          <Row className="mt-4">
            <Col xs={"auto"} className="caption">
              {`Escoge la o las partes que quieras agregar en la solicitud`}
            </Col>
          </Row>
          <br />
          <Row style={{ rowGap: 15 }}>
            <Col xs={7} xl={5}>
              <CustomSearchFilterButton
                placeholder="Buscar"
                list={data}
                setFilteredData={setFilteredData}
                validations={{ maxLength: 20 }}
              />
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <Col xs={"auto"}>
                {signatoriesList.length ? (
                  <FilterSignatory
                    list={data}
                    setFilteredInfo={setFilteredData}
                    handleSignatorySelected={handleSignatoriesSelected}
                    sigantoryList={signatoriesList}
                    currentSignatoriesSelected={signatoriesSelected}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Col>
          </Row>

          <br />
          <Row style={{ justifyContent: "end" }}>
            {signatoriesSelected.map((signatory) => {
              return (
                <Col xs={"auto"} key={signatory?._id}>
                  <span className="caption custom-badges__secundary">
                    {signatory.name + " "}{" "}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() =>
                        handleDeleteSignatorySelected(signatory._id)
                      }
                    />
                  </span>
                </Col>
              );
            })}
          </Row>
          <br />
          <Row
            className="data-table dt-list-items"
            style={{ marginBottom: "7rem" }}
          >
            {
              <MDBDataTableV5
                noRecordsFoundLabel={
                  !data?.rows?.length
                    ? "Aún no tienes partes creadas"
                    : "No se encontraron resultados para tu búsqueda"
                }
                hover
                pagingTop
                searchBottom={false}
                entries={10}
                data={filteredData}
                infoLabel={["Mostrando", "a", "de", "firmantes"]}
                fullPagination
                onSort={(value) => {
                  onSortHTMLElement({
                    excludesColumns: ["actions", "corporateUnits"],
                    sort: value,
                    filteredDataTable: filteredData,
                    setFilteredDataTable: setFilteredData,
                  });
                }}
              />
            }
          </Row>

          <ModalInfo
            title="Sin partes guardadas"
            onClose={() => setOpenModalNoParts(false)}
            open={openModalNoParts}
            responseData={{
              data: {
                message: "No existen partes guardadas en biblioteca",
              },
            }}
            confirmationText="Aceptar"
            buttonClose={true}
          />
        </Container>
      </Modal.Body>
      <Row className="sidebar__bottom__container">
        <Col xs={2}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            startIcon={<CheckIcon fontSize="large" />}
            disabled={!selectedParts.length}
            onClick={handleAddPart}
          >
            Seleccionar
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
