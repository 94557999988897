import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../components/Login/Login";
import InfoSubtypeRequest from "../components/ExternalRequest/InfoSubtypeRequest";
import ForgotPassword from "../pages/auth/forgotPassword";
import Layout from "../pages/parts/layout";
import ProtectedRoute from "./protectedRoutes";
import CreatePassword from "../components/ExternalRequest/CreatePassword";
import InfoStatus from "../components/ExternalRequest/InfoStatus";
import SignModule from "../views/Carvajal/SignModule";
import SignatoryData from "../views/Carvajal/SignatoryData";
import DataTableCarvajal from "../views/Carvajal/DataTableCarvajal";
import ApprovalsService from "../views/Carvajal/Approvals/CreateApproval/approvalsService";
import ViewApproval from "../views/Carvajal/Approvals/CreateApproval/viewApproval";
import CreateApproval from "../views/Carvajal/Approvals/CreateApproval/createApproval";
import RequestApprovalAgain from "../views/Carvajal/Approvals/RequestApprovalAgain/requestApprovalAgain";
import ApproverLayout from "../pages/parts/approverLayout";
import AdjustmentService from "../views/Carvajal/Approvals/CreateApproval/adjustments/adjustmentService";
import AdjustmentConfirmation from "../views/Carvajal/Approvals/CreateApproval/adjustments/adjustmentConfirmation";
import ApprovalConfirmed from "../views/Carvajal/Approvals/CreateApproval/adjustments/approvalConfirmed";
import ViewRequestStandard from "../views/Standard/ViewRequestStandard";
import SelectCorp from "../views/SelectCorp";
import NotFound from "../views/NotFound";
import ExternalHelp from "../views/ExternalHelp";
import CreateService from "../views/Create/createService";
import SelectContractType from "../views/Create/selectContractType";
import { ViewContract } from "../views/Create/viewContract";
import ProtectedMaintenance, {
  ModuleMaintenance,
} from "./protectedMaintenance";
import SelectHeadings from "../views/Create/selectHeadings";
import { DownloadFile } from "./downloadFile";
import CreateRequest from "../views/Standard/CreateRequest";
import ManageSignature from "../views/manageSignature";

function AppRoutes() {
  return (
    <Routes>
      <Route element={<ProtectedMaintenance />}>
        <Route index element={<Login />} />

        <Route element={<ProtectedRoute requiredRole={"EXTERNAL"} />}>
          <Route path="selectCorp" element={<SelectCorp />} />
          <Route path="external" element={<Layout />}>
            {/* <Route path="create" element={<CreateNewRequest />} /> */}

            <Route path="create" element={<CreateService />} />
            <Route path="create/request" element={<CreateRequest />} />
            <Route path="create/contract" element={<SelectContractType />} />
            <Route path="create/contract/:id" element={<ViewContract />} />
            <Route
              path="create/contract/:id/select-headings"
              element={<SelectHeadings />}
            />
            <Route path="help" element={<ExternalHelp />} />

            <Route path="type" element={<InfoSubtypeRequest />} />
            <Route path="infoStatus" element={<InfoStatus />} />
            <Route path="view/:consecutive" element={<ViewRequestStandard />} />
            {/* Mismos path con nuevos componentes */}
            <Route
              path="view/:consecutive/approvals"
              element={<ApprovalsService />}
            />
            <Route
              path="view/:consecutive/approvals/viewApproval/:approvalId"
              element={<ViewApproval />}
            />
            <Route
              path="view/:consecutive/approvals/create-approval"
              element={<CreateApproval />}
            />
            <Route
              path="view/:consecutive/signatures"
              element={<ManageSignature />}
            />
            <Route
              path="view/:consecutive/approvals/viewApproval/:approvalId/request-again-approval/:type"
              element={<RequestApprovalAgain />}
            />
            <Route path="home" element={<DataTableCarvajal />} />
            <Route path="requests/:consecutive/sign" element={<SignModule />} />
            <Route
              path="requests/:consecutive/sign/:requestId/documentType/:documentType/documentId/:documentId"
              element={<SignatoryData />}
            />
          </Route>
        </Route>
        <Route path="forgotPassword/:email" element={<ForgotPassword />} />
        <Route path="createPassword/:email" element={<CreatePassword />} />
        <Route path="approver" element={<ApproverLayout />}>
          <Route
            path="approvalAdjustment/:consecutive/:approvalId"
            element={<AdjustmentService />}
          />
          <Route
            path="approvalAdjustment/:consecutive/:approvalId/confirmation"
            element={<AdjustmentConfirmation />}
          />
          <Route
            path=":consecutive/:approvalId/approved"
            element={<ApprovalConfirmed />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="maintenance" element={<ModuleMaintenance />} />
      <Route path="downloadFile" element={<DownloadFile />} />
    </Routes>
  );
}
export default AppRoutes;
