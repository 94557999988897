import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const LoadingContent = () => {
  const [time, setTime] = useState(0);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (!showModal) {
      if ((time + 1) < 6) {
        const idTimer = setInterval(() => {
          setTime(oldTime => oldTime + 1);
        }, 1000);
        return () => clearInterval(idTimer);
      }
      if (time === 5) {
        setShowModal(true);
        setTime(0);
      }
    }
  }, [time, showModal])
  return (<>
    {!showModal && (
      <div className="loading-content-linear loading-content-linear--spinner">
        <Box style={{
          position: 'fixed',
          right: 0,
          top: 0,
          width: '100%',
        }}>
          <LinearProgress />
        </Box>
      </div>
    )}
    {showModal && (<ModalLoadingText open={showModal} />)}
  </>
  );
};

export const ModalLoadingText = ({ open }) => {

  const [progress, setProgress] = useState(0);

  const MAX_PROGRESS = 99;
  const PROGRESS_INCREMENT_SMALL = 0.1;
  const PROGRESS_INCREMENT_LARGE = 2.5;
  const UPDATE_INTERVAL = 1000;

  const updateProgress = (prevProgress) => {
    if (prevProgress === MAX_PROGRESS) return MAX_PROGRESS;
    if (prevProgress >= 90) return prevProgress + PROGRESS_INCREMENT_SMALL;
    return prevProgress + PROGRESS_INCREMENT_LARGE;
  };

  useEffect(() => {
    if (open) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => updateProgress(prevProgress));
      }, UPDATE_INTERVAL);

      return () => {
        clearInterval(interval);
      };
    }
  }, [open]);

  return (
    <div className="loading-content loading-content--spinner">
      <Dialog
        fullWidth
        open={open}
      >
        <DialogTitle>
          <label className="heading__primary-color">
            Procesando información
          </label>
        </DialogTitle>
        <DialogContent className="caption" dividers>
          <Row>
            <label>
              Por favor, espera unos momentos mientras procesamos la información.
            </label>
          </Row>
          <br />
          <Row>
            <Box sx={{ width: '100%' }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: "#00374F",
                  },
                }}
                style={{ height: 8, borderRadius: 4, backgroundColor: "#F0F0F7" }}
              />
            </Box>
          </Row>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LoadingContent;
