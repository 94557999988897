import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordService } from "../../services/Login/Login";
import { CODES } from "../../utils/codes";
import SubModalGeneral from "./SubModalGeneral";

const ModalForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("*Este campo debe ser un email válido")
      .required("*Este campo es requerido"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleForgotPassword = async (data) => {
    try {
      setIsLoading(true);
      const obj = {
        email: data.email,
      };
      const forgotPasswordServiceResponse = await ForgotPasswordService(obj);
      if (
        forgotPasswordServiceResponse.status === CODES.COD_RESPONSE_HTTP_OK ||
        forgotPasswordServiceResponse.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        if (
          forgotPasswordServiceResponse.data.success &&
          forgotPasswordServiceResponse.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
        ) {
          navigate(`/forgotPassword/${data.email}`);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Button className="custom-input__button" onClick={handleClickOpen}>
        <u className="custom-input__button__link">Olvidé mi contraseña</u>
      </Button>
      <Dialog
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Form onSubmit={handleSubmit(handleForgotPassword)}>
          <SubModalGeneral onClose={handleClose}>
            <label className="heading__primary-color">
              Recuperar Contraseña
            </label>
          </SubModalGeneral>
          <DialogContent dividers>
            <Form.Group className="mb-5">
              <Form.Label className="caption__primary-color">
                Al ingresar su correo se enviará un código con el que podrá
                recuperar su contraseña
              </Form.Label>
              <InputGroup
                bsPrefix={
                  errors.email
                    ? "input-group-container__error"
                    : "input-group-container"
                }
              >
                <InputGroup.Text bsPrefix="container-icon">
                  <AlternateEmailIcon fontSize="large" />
                </InputGroup.Text>
                <Form.Control
                  {...register("email")}
                  bsPrefix="custom-input"
                  placeholder="Correo electrónico"
                />
              </InputGroup>
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.email?.message}
              </div>
            </Form.Group>
          </DialogContent>
          <DialogActions>
            <div>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                className="custom-input__button__primary-color"
              >
                {isLoading ? (
                  <Spinner
                    animation="border"
                    className="spinner__white-background"
                  />
                ) : (
                  "Aceptar"
                )}
              </Button>
            </div>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default ModalForgotPassword;
