import React, { useState } from "react";
import { Col, Container, Row, Form, Spinner, Modal } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrum from "../../pages/parts/breadcrum";
import SectionTitle from "../../components/sectionTitle";
import { useFieldArray, useForm } from "react-hook-form";
import { SendPersonalDataToSign } from "../../services/CarvServices/CarvajalServices";
import { CODES } from "../../utils/codes";
import LoadingContent from "../../components/LoadingContent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { REGEXP } from "../../utils/regexp";
import Tooltip from "@mui/material/Tooltip";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import GetToken from "../../utils/getToken";
import "@react-pdf-viewer/core/lib/styles/index.css";
import ModalInfo from "../../components/Modals/ModalInfo";
import { env } from "../../env";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PopoverInfo from "../../components/popover/popoverInfo";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { countries } from "../../utils/countries";
import { Mixpanel } from "../../utils/mixPanel";

const SignatoryData = () => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const navigate = useNavigate();
  const { consecutive, requestId, documentType, documentId } = useParams();
  const info = JSON.parse(localStorage.payloadToken);
  const { firstName, firstSurname, userId, companyName } = info;
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [path, setPath] = useState("");
  const [isOpenModaltoCancel, setIsOpenModaltoCancel] = useState(false);
  const [isDisableContinueButton, setIsDisableContinueButton] = useState(false);
  const [openModalInfoSign, setOpenModalInfoSign] = useState(false);
  const [responseData, setResponseData] = useState({});
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  const schema = yup.object().shape({
    signatories: yup.array().of(
      yup.object().shape({
        firstName: yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_LETTERS, {
            message: "*Solo debe incluir letras",
          }),
        firstSurname: yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_LETTERS, {
            message: "*Solo debe incluir letras",
          }),
        documentNumber: yup
          .string()
          .required("*Este campo es obligatorio")
          .test("is-valid-number", "*Solo debe incluir números ", (value) => {
            if (!value) {
              return false;
            }
            return REGEXP.ONLY_NUMBERS.test(value);
          })
          .min(7, "Mínimo 7 números"),
        email: yup
          .string()
          .email("*Este campo debe ser un email válido")
          .required("*Este campo es requerido"),
        messageToSignatory: yup.string().required("*Este campo es obligatorio"),
        countryCode: yup.string().notRequired(),
        phoneNumber: yup
          .string()
          .notRequired()
          .test("onlyNumbers", "*Solo debe incluir números", function (value) {
            if (!!value) {
              const schema = yup.string().matches(REGEXP.ONLY_NUMBERS);
              return schema.isValidSync(value);
            }
            return true;
          })
          .test("min", "*Mínimo 10 números", function (value) {
            if (!!value) {
              const schema = yup.string().min(10, "");
              return schema.isValidSync(value);
            }
            return true;
          })
          .test("max", "*Máximo 10 números", function (value) {
            if (!!value) {
              const schema = yup.string().max(10, "");
              return schema.isValidSync(value);
            }
            return true;
          }),
      })
    ),
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      signatories: [{}],
    },
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "signatories",
  });

  const handleClosePreview = () => {
    setShow(false);
  };

  const handleZoom = (doc, scale) => {
    console.log(`Zoom document to ${scale}`);
  };

  const handlePreviewDoc = () => {
    setShow(true);
    const company = localStorage.getItem("company");
    const corporateUnit = JSON.parse(localStorage.getItem("corporateUnit"))._id;
    setPath(
      `${env.REACT_APP_API_URL}/thirdPartyRequest/get/document?consecutive=${consecutive}&documentId=${documentId}&documentType=${documentType}&corporateUnit=${corporateUnit}&companyId=${company}`
    );
  };

  const handleSendSignatoryData = async (data) => {
    try {
      setIsLoading(true);
      Mixpanel.track("Solicitante Btn Solicitar Firma", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      const obj = {
        signatoriesInfo: data.signatories,
        requestId: requestId,
        authorName: `${firstName || companyName || ""} ${firstSurname || ""}`,
        userId: userId,
      };

      const service = await SendPersonalDataToSign(obj);
      if (service.status) {
        if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
          navigate("/external/home", { replace: true });
          return;
        }
      }
      setOpenModalInfoSign(true);
      setResponseData(service);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const infoToCancel = {
    data: {
      responseMessage: "El proceso de firma ha sido cancelado.",
    },
    status: "",
  };

  const onCloseModalCancelSign = () => {
    setIsOpenModaltoCancel(false);
    navigate("/external/home");
  };

  const handleAddSignatory = () => {
    append({});
  };

  const getNameSignatory = (index) => {
    const data = watch(`signatories.${index}`);
    const name = `${data?.firstName || ""} ${data?.secondName || ""} ${
      data?.firstSurname || ""
    } ${data?.secondSurname || ""} `;
    return name;
  };

  const changePositionSignatory = ({ source, destination }) => {
    const isSameIndex = source.index === destination.index;
    if (!source || !destination || isSameIndex) return;
    const [removed] = fields.splice(source.index, 1);
    fields.splice(destination.index, 0, removed);
    const data = getValues()?.signatories;
    const [removedData] = data.splice(source.index, 1);
    data.splice(destination.index, 0, removedData);
    setValue("signatories", data);
  };

  const handleDeleteFieldsSignatory = (index) => {
    remove(index);
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={`Firmar documento - Solicitud ${consecutive}`} />
      </Row>
      <Row xs={"auto"}>
        <p
          className="home-init-cards-row__title-row"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          Datos del firmante
        </p>
      </Row>
      <Row>
        <Form onSubmit={handleSubmit(handleSendSignatoryData)}>
          <DragDropContext
            onDragEnd={(result) => {
              changePositionSignatory(result);
            }}
          >
            <Droppable droppableId="forms-signatories">
              {(droppableProvided) => (
                <div
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                  className="custom-accordion__container-headers"
                >
                  {fields.map((item, index) => (
                    <Draggable
                      key={item?.id}
                      draggableId={`${item?.id}-draggable-form`}
                      index={index}
                    >
                      {(draggablePorvider) => (
                        <Accordion
                          {...draggablePorvider.draggableProps}
                          {...draggablePorvider.dragHandleProps}
                          className="custom-accordion"
                          ref={draggablePorvider.innerRef}
                          defaultExpanded={index === 0}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Row
                              style={{
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "end",
                              }}
                            >
                              <Col xs={"auto"}>
                                <DragIndicatorIcon
                                  fontSize="large"
                                  className="drag-color"
                                />
                              </Col>
                              <Col>
                                <p className="heading__primary-color">
                                  {getNameSignatory(index)}
                                  <span className="caption">
                                    Firmante #{index + 1}
                                  </span>
                                </p>
                              </Col>
                              {Object.keys(errors).length > 0 && (
                                <Col xs={"auto"}>
                                  <PopoverInfo
                                    section={{
                                      description:
                                        "Debe completar todos los campos obligatorios",
                                    }}
                                    icon={
                                      <InfoOutlinedIcon
                                        fontSize="large"
                                        color="error"
                                      />
                                    }
                                  />
                                </Col>
                              )}
                              {fields.length > 1 && (
                                <Col xs={"auto"}>
                                  <IconButton
                                    aria-label="delete"
                                    className="custom-input__button__primary-color"
                                    sx={{ borderRadius: "8px", color: "white" }}
                                    onClick={() => {
                                      handleDeleteFieldsSignatory(index);
                                    }}
                                  >
                                    <DeleteIcon fontSize="large" />
                                  </IconButton>
                                </Col>
                              )}
                            </Row>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormSignatory
                              register={register}
                              errors={errors}
                              index={index}
                              setValue={setValue}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <br />
          <Row style={{ rowGap: 10 }}>
            <Col xs={"auto"}>
              <Tooltip title={<h5>Agrega un nuevo firmante</h5>}>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  type="button"
                  onClick={handleAddSignatory}
                  startIcon={<AddOutlinedIcon fontSize="large" />}
                >
                  Agregar firmante
                </Button>
              </Tooltip>
            </Col>
            <Col></Col>
            <Col xs={"auto"}>
              <Tooltip title={<h5>Visualizar documento a firmar</h5>}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  type="button"
                  onClick={handlePreviewDoc}
                  startIcon={<RemoveRedEyeOutlinedIcon fontSize="large" />}
                >
                  Visualizar
                </Button>
              </Tooltip>
            </Col>
            <Col xs={"auto"}>
              <Tooltip title={<h5>Cancelar proceso de firma</h5>}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  type="button"
                  onClick={() => setIsOpenModaltoCancel(true)}
                >
                  Cancelar
                </Button>
              </Tooltip>
            </Col>
            <Col xs={"auto"}>
              <Tooltip title={<h5>Continuar con el proceso de firma</h5>}>
                <Button
                  variant="contained"
                  className={
                    isDisableContinueButton
                      ? "custom-input__button__primary-color__disabled"
                      : "custom-input__button__primary-color"
                  }
                  type="submit"
                  disabled={isDisableContinueButton}
                >
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      className="spinner__blue_background"
                    />
                  ) : (
                    "Continuar"
                  )}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form>
      </Row>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
        <Modal show={show} onHide={handleClosePreview} scrollable>
          <Modal.Header closeLabel="cerrar" closeButton>
            <Modal.Title
              className="heading-secondary"
              style={{ marginRight: "6rem" }}
            >
              Documento a firmar
            </Modal.Title>

            <>
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
            </>
          </Modal.Header>
          <Modal.Body>
            <Viewer
              plugins={[zoomPluginInstance]}
              fileUrl={path}
              onZoom={handleZoom}
              httpHeaders={{
                Authorization: GetToken(),
              }}
            />
          </Modal.Body>
        </Modal>
      </Worker>
      <ModalInfo
        title="Proceso de firma cancelado"
        responseData={infoToCancel}
        onClose={onCloseModalCancelSign}
        open={isOpenModaltoCancel}
        confirmationText="Aceptar"
      />
      <ModalInfo
        title={
          responseData?.data?.responseCode ===
          CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
            ? "Límite de firmas"
            : "Información"
        }
        responseData={responseData}
        onClose={() => {
          if (
            responseData?.data?.responseCode ===
            CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
          ) {
            setIsDisableContinueButton(true);
          }
          setOpenModalInfoSign(false);
        }}
        open={openModalInfoSign}
        confirmationText="Aceptar"
        buttonClose={true}
        handleCloseDefault={() => {
          if (
            responseData?.data?.responseCode ===
            CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
          ) {
            setIsDisableContinueButton(true);
          }
          setOpenModalInfoSign(false);
        }}
      />
    </Container>
  );
};

const FormSignatory = ({ index, register, errors, setValue }) => {
  return (
    <>
      <Row>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form__label">
              Primer nombre:<span style={{ color: "red" }}>{"* "}</span>
            </Form.Label>
            <Form.Control
              {...register(`signatories.${index}.firstName`)}
              type="text"
              placeholder="Primer nombre"
              bsPrefix={
                errors?.signatories && errors?.signatories[index]?.firstName
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors?.signatories &&
                errors?.signatories[index]?.firstName?.message}
            </p>
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form__label">Segundo nombre:</Form.Label>
            <Form.Control
              {...register(`signatories.${index}.secondName`)}
              type="text"
              placeholder="Segundo nombre"
              bsPrefix={"input-group-container__no-icon label"}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form__label">
              Primer apellido:<span style={{ color: "red" }}>{"* "}</span>
            </Form.Label>
            <Form.Control
              {...register(`signatories.${index}.firstSurname`)}
              type="text"
              placeholder="Primer apellido"
              bsPrefix={
                errors?.signatories && errors?.signatories[index]?.firstSurname
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors?.signatories &&
                errors?.signatories[index]?.firstSurname?.message}
            </p>
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form__label">Segundo apellido:</Form.Label>
            <Form.Control
              {...register(`signatories.${index}.secondSurname`)}
              type="text"
              placeholder="Segundo apellido"
              bsPrefix={"input-group-container__no-icon label"}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form__label">
              No. Documento:<span style={{ color: "red" }}>{"* "}</span>
            </Form.Label>
            <Form.Control
              {...register(`signatories.${index}.documentNumber`)}
              placeholder="No. documento"
              bsPrefix={
                errors?.signatories &&
                errors?.signatories[index]?.documentNumber
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors?.signatories &&
                errors?.signatories[index]?.documentNumber?.message}
            </p>
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form__label">
              Correo electrónico:<span style={{ color: "red" }}>{"* "}</span>
            </Form.Label>
            <Form.Control
              {...register(`signatories.${index}.email`)}
              type="email"
              placeholder="Correo electrónico"
              bsPrefix={
                errors?.signatories && errors?.signatories[index]?.email
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors?.signatories &&
                errors?.signatories[index]?.email?.message}
            </p>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicContactInfo">
            <Form.Label className="form__label">Código de país:</Form.Label>
            <Select
              defaultValue={"57"}
              className={
                errors?.signatories?.[index]?.countryCode
                  ? "input-group-container__no-icon__country-list__error label"
                  : "input-group-container__no-icon__country-list label"
              }
              {...register(`signatories.${index}.countryCode`)}
              onChange={(e) => {
                setValue(`signatories.${index}.countryCode`, e.target.value);
              }}
              sx={{
                "& .MuiSelect-select": { padding: 0 },
              }}
            >
              <MenuItem
                value={""}
                className="input-group-container__no-icon__country-list label"
                style={{ border: "none !important" }}
              >
                Selecciona
              </MenuItem>
              {countries.map((c) => (
                <MenuItem
                  key={c.code}
                  value={c.phone}
                  className="input-group-container__no-icon__country-list label"
                  style={{ border: "none !important" }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                    alt={`Flag of ${c.label}`}
                  />
                  {` ${c.label} (${c.code}) +${c.phone}`}
                </MenuItem>
              ))}
            </Select>
            <div className="caption custom-input__error">
              {errors?.signatories?.[index]?.countryCode?.message}
            </div>
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicContact">
            <Form.Label className="form__label">Número de celular:</Form.Label>
            <Form.Control
              {...register(`signatories.${index}.phoneNumber`)}
              type="text"
              placeholder="Número de celular"
              bsPrefix={
                errors?.signatories?.[index]?.phoneNumber
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors?.signatories?.[index]?.phoneNumber?.message}
            </p>
          </Form.Group>
        </Col>
      </Row>
      <Row xs={"auto"}>
        <p
          className="home-init-cards-row__title-row"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          Mensaje
        </p>
      </Row>
      <Row>
        <Col md={5} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form__label">
              Escribe un mensaje al firmante
              <span style={{ color: "red" }}>{"* "}</span>
            </Form.Label>
            <Form.Control
              {...register(`signatories.${index}.messageToSignatory`)}
              type="text"
              as="textarea"
              rows={3}
              placeholder="Ej. Cordial saludo, a continuación iniciaré con el proceso de la firma."
              bsPrefix={
                errors?.signatories &&
                errors?.signatories[index]?.messageToSignatory
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              maxLength={250}
            />
            <p className="caption custom-input__error">
              {errors?.signatories &&
                errors?.signatories[index]?.messageToSignatory?.message}
            </p>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default SignatoryData;
