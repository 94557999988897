import React from "react";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
import { saveAs } from "file-saver";
import LoadingContent from "../components/LoadingContent";
import SectionTitle from "../components/sectionTitle";
import Breadcrum from "../pages/parts/breadcrum";
import { getFileDocument } from "../services/Approvals/ApprovalServices";
import {
  deleteSignature,
  findAllSignaturesByConsecutive,
  returnSignture,
} from "../services/signatures/signaturesServices";
import { CODES } from "../utils/codes";
import ModalInfo from "../components/Modals/ModalInfo";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModalDecision from "../components/Modals/ModalDecision";

export const ManageSignature = () => {
  //Constants
  const columnsSignatures = [
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="1"
        >
          Documento para firma
        </label>,
      ],
      field: "document",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="2"
        >
          Fecha de solicitud
        </label>,
      ],
      field: "date",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="3"
        >
          Fecha de firma o devolución
        </label>,
      ],
      field: "dateResponse",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="4"
        >
          Firmantes
        </label>,
      ],
      field: "signatory",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="5"
        >
          Estado
        </label>,
      ],
      field: "state",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="6"
        >
          Comentarios
        </label>,
      ],
      field: "comments",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="8"
        >
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];

  //Variables - useState
  const { consecutive } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSignatures, setDataSignatures] = useState({
    rows: [],
    columns: columnsSignatures,
  });
  const [isOpenModalResume, setIsOpenModalResume] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isOpenModalDeleteSignature, setIsOpenModalDeleteSignature] = useState({open: false, id: null});
  const [isOpenModalDeleteResponse, setIsOpenModalDeleteResponse] = useState(false);

  //Handlers
  const handleReturnSignature = async (signatureId, restartOTP = false) => {
    try {
      setIsLoading(true);
      const response = await returnSignture({ signatureId, restartOTP });
      fetchData();
      setIsOpenModalResume(true);
      setResponseData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickDocument = async (data) => {
    try {
      setIsLoading(true);
      const file = await getFileDocument(
        data.filenameInBucket,
        data.bucketName,
        false
      );
      const bf = file.data.responseMessage.buffer.data;
      const blob = new Blob([new Uint8Array(bf).buffer]);
      let fileName = file.data.responseMessage.filename;

      saveAs(blob, fileName);
    } catch (error) {
      console.log(
        "========= Start Handle Download Document To Sign Service ========="
      );
      console.error(error);
      console.log(
        "========= End Handle Download Document To Sign Service ========="
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSignature = async (id) => {
    try {
      setIsOpenModalDeleteSignature({open: false, id: null});
      setIsLoading(true);
      const responseDelete = await deleteSignature(id);
      if (responseDelete.status === CODES.COD_RESPONSE_HTTP_OK) {
        fetchData();
      }
      setResponseData(responseDelete);
      setIsOpenModalDeleteResponse(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const buildRowsSignatures = (data) => {
    const getStateStyle = (state) => {
      if (state.hasSigned) return { color: "#CCF4E3", name: "Firmado" };
      if (state.hasRejected) return { color: "#E6E9EF", name: "Devuelto" };
      return { color: "#FFD7B1", name: "Pendiente" };
    };

    return data.map((item) => {
      const signatories = (
        <Row style={{ alignItems: "center" }}>
          <ul style={{ listStyle: "none", margin: 0 }}>
            {item?.signatoriesInfo.map((signatory) => (
              <li key={signatory?._id} style={{ paddingBottom: "3px" }}>
                {`${signatory?.firstName || ""} ${
                  signatory?.firstSurname || ""
                }`}
              </li>
            ))}
          </ul>
        </Row>
      );
      const filterRejected = item?.signatoriesInfo?.filter(
        (signatory) => signatory?.hasRejected
      );
      const filterSigned = item?.signatoriesInfo?.filter(
        (signatory) => signatory?.hasSigned
      );
      const dateResponse =
        !filterSigned.length && !filterRejected.length
          ? "---"
          : item?.updatedAt;
      const isRejected = filterRejected.length > 0;
      const states = (
        <Row style={{ alignItems: "center" }}>
          <ul style={{ listStyle: "none", margin: 0 }}>
            {item?.signatoriesInfo.map((signatory) => (
              <li key={signatory?._id} style={{ paddingBottom: "3px" }}>
                <span
                  className="custom-badge"
                  style={{ backgroundColor: getStateStyle(signatory).color }}
                >
                  {getStateStyle(signatory).name}
                </span>
              </li>
            ))}
          </ul>
        </Row>
      );

      let objDownload = {};
      if (!item?.documentAssociated) {
        objDownload = {
          _id: item?.documentId,
          type: item?.documentType,
        };
      } else {
        objDownload = item.documentAssociated;
      }

      const getComment = () => {
        if (isRejected) {
          const message = filterRejected.map((item) => item.rejectionMessage).join(", ");
         return (<p>{message}</p>);
        } else if (item?.noOTPAvailable && item?.isSuspended) {
          return (
            <>
              <p>
                El firmante ha alcanzado el máximo de intentos disponibles para
                firmar.
              </p>
              <span
                className="caption__other-Color-Active"
                onClick={() => handleReturnSignature(item?._id, true)}
              >
                Reanudar firma
              </span>
            </>
          );
        } else {
          return (<p>Sin comentarios</p>);
        }
      };

      return {
        document: (
          <label
            onClick={() => handleClickDocument(objDownload)}
            style={{
              textDecoration: "underline",
              color: "#067BBD",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
          >
            {item?.documentAssociated?.originalFilename || "Sin documento"}
          </label>
        ),
        date: item?.createdAt,
        dateResponse,
        signatory: signatories,
        state: states,
        comments: getComment(),
        actions: (<Row>
          <Col xs={"auto"}>
            <Tooltip title={<h5>Elimina un proceso de firma en curso</h5>}>
              <IconButton
                aria-label="delete"
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDeleteSignature({ open: true, id: item?._id })}
              >
                <DeleteOutlineOutlinedIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Col>
          {!item?.isSigned && !item?.isRejected && (
            <Col xs={"auto"}>
              <Tooltip title={<h5>Reenviar recordatorio para iniciar proceso de firma</h5>}>
                <IconButton
                  aria-label="remember"
                  className="custom-input__button__secondary-color"
                  onClick={() => handleReturnSignature(item?._id)}
                >
                  <NotificationsActiveOutlinedIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Col>
          )}
        </Row>)
      };
    });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      
      const companyId = localStorage.getItem("company");
      const corporateUnitId = JSON.parse(localStorage.getItem("corporateUnit"))._id;

      const responseSignature = await findAllSignaturesByConsecutive({
        consecutive,
        companyId,
        corporateUnitId
      });
      const rowsSignatures = [];
      if (
        responseSignature.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseSignature.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const data = responseSignature.data.responseMessage;
        const buildRows = buildRowsSignatures(data);
        rowsSignatures.push(...buildRows);
      }
      setDataSignatures({ rows: rowsSignatures, columns: columnsSignatures });
    } catch (error) {
      console.log(
        "=========== Start Fetch All Signatures Service ==========="
      );
      console.error(error);
      console.log("=========== End Fetch All Signatures Service ===========");
    } finally {
      setIsLoading(false);
    }
  }

  //useEffects
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <Col xs={"auto"}>
          <SectionTitle title={`Solicitud ${consecutive || "###"}`} />
        </Col>
      </Row>
      <br />
      <br />
      <Divider />
      <br />
      <Row>
        <p className="heading__primary-color">Información de firmas</p>
      </Row>
      <br />
      <Row>
        <p className="caption">
          Aquí puedes encontrar un historico de las firmas relacionadas con la{" "}
          <b>solicitud {consecutive || "###"}.</b>
        </p>
      </Row>
      <br />
      <Row className="dt-list-items">
        <MDBDataTableV5
          hover
          pagingTop
          searchBottom={false}
          entries={6}
          data={dataSignatures}
          entriesLabel=""
          infoLabel={["Mostrando", "a", "de", "firmas"]}
          fullPagination
          noRecordsFoundLabel="No se encontraron resultados"
        />
      </Row>
      <ModalInfo
        title="Reanudar firma"
        onClose={() => setIsOpenModalResume(false)}
        open={isOpenModalResume}
        responseData={responseData}
        confirmationText="Aceptar"
      />
      <ModalDecision
        title={"Eliminar"}
        message={"¿Seguro que quieres eliminar este proceso de firma?"}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => handleDeleteSignature(isOpenModalDeleteSignature.id)}
        onClose={() => {
          setIsOpenModalDeleteSignature({open: false, id: null});
        }}
        handleDisagree={() => {
          setIsOpenModalDeleteSignature({open: false, id: null});
        }}
        open={isOpenModalDeleteSignature.open}
      />
      <ModalInfo
        title="Eliminar firma"
        onClose={() => setIsOpenModalDeleteResponse(false)}
        open={isOpenModalDeleteResponse}
        responseData={responseData}
        confirmationText="Aceptar"
      />
    </Container>
  );
};
export default ManageSignature;
