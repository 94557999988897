import GetToken from "../../utils/getToken";
import axios from "axios";
import { env } from "../../env";

export const findAllSignaturesByConsecutive = ({consecutive, companyId, corporateUnitId }) => {
    return axios({
        method: "GET",
        url: "signatures/findAllSignaturesByConsecutive",
        baseURL: env.REACT_APP_API_URL,
        validateStatus: null,
        params: { 
            consecutive,
            companyId,
            corporateUnitId
        },
        headers: {
            Authorization: GetToken(),
        },
    });
};

export function returnSignture({ signatureId, restartOTP }) {
    return axios({
        method: "PUT",
        url: `signatures/resume/signature/${signatureId}`,
        baseURL: env.REACT_APP_API_URL,
        headers: {
            Authorization: GetToken(),
        },
        data: {
          restartOTP: restartOTP,
        },
    });
}

export const deleteSignature = (signatureId) => {
  return axios({
    method: "DELETE",
    url: `signatures/deleteSignature`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      signatureId,
      signatureType: "request",
    },
    headers: {
      Authorization: GetToken(),
    },
  });

}